import { render, staticRenderFns } from "./actionMenu.vue?vue&type=template&id=ef64549c&scoped=true"
import script from "./actionMenu.vue?vue&type=script&lang=js"
export * from "./actionMenu.vue?vue&type=script&lang=js"
import style0 from "./actionMenu.vue?vue&type=style&index=0&id=ef64549c&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ef64549c",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VContainer } from 'vuetify/lib/components/VGrid';
installComponents(component, {VBtn,VContainer})


/* vuetify-loader */
import installDirectives from "!../../node_modules/vuetify-loader/lib/runtime/installDirectives.js"
import ClickOutside from 'vuetify/lib/directives/click-outside'
installDirectives(component, {ClickOutside})
