var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"pbx-container",attrs:{"fluid":""}},[_c('v-row',{staticClass:"pbx-header",attrs:{"no-gutter":"","justify":"space-between"}},[_c('v-col',{staticClass:"text-bold-24 pa-0",attrs:{"sm":"auto"}},[_vm._v(_vm._s(_vm.$t("sources.sources")))]),_c('v-col',{staticClass:"pa-0",attrs:{"sm":"auto"}},[_c('v-btn',{staticClass:"button-text-16 no-text-transform",attrs:{"color":"primary","width":"150","dark":"","rounded":"","outlined":""},on:{"click":function($event){return _vm.importPbx()}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("add")]),_vm._v(" "+_vm._s(_vm.$t("sources.import"))+" ")],1),_c('v-btn',{staticClass:"button-text-16 no-text-transform left-margin",attrs:{"color":"primary","width":"150","rounded":"","outlined":"","disabled":_vm.noPbxImported},on:{"click":function($event){return _vm.confirmDeleteCustomer()}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("delete")]),_vm._v(" "+_vm._s(_vm.$t("sources.deleteAll"))+" ")],1)],1)],1),_c('v-row',{style:(_vm.cssVariables),attrs:{"no-gutter":""}},[_c('v-data-table',{staticClass:"data-table full-width",attrs:{"headers":_vm.headers,"items":_vm.pbxItems,"options":_vm.options,"server-items-length":_vm.totalElements,"loading":_vm.loading,"no-data-text":_vm.$t('common.noDataAvailable'),"footer-props":{
          disablePagination: _vm.loading,
          itemsPerPageOptions: [5, 10, 20],
          showFirstLastPage: true,
          firstIcon: 'first_page',
          prevIcon: 'chevron_left',
          nextIcon: 'chevron_right',
          lastIcon: 'last_page',
          itemsPerPageText: _vm.$t('common.rowsPerPage')
      }},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"header.description",fn:function(ref){
      var header = ref.header;
return [_vm._v(" "+_vm._s(_vm.$t(header.text))+" ")]}},{key:"header.name",fn:function(ref){
      var header = ref.header;
return [_c('v-text-field',{attrs:{"value":_vm.name,"label":_vm.$t(header.text),"append-icon":"search","clear-icon":"close","single-line":"","hide-details":"","flat":"","solo":"","clearable":"","placeholder":_vm.$t(header.text)},on:{"change":function ($event) { return _vm.name = $event; },"click:clear":function ($event) { return _vm.name = ''; }}})]}},{key:"header.created",fn:function(ref){
      var header = ref.header;
return [_vm._v(" "+_vm._s(_vm.$t(header.text))+" ")]}},{key:"header.synced",fn:function(ref){
      var header = ref.header;
return [_vm._v(" "+_vm._s(_vm.$t(header.text))+" ")]}},{key:"header.updated",fn:function(ref){
      var header = ref.header;
return [_vm._v(" "+_vm._s(_vm.$t(header.text))+" ")]}},{key:"header.actions",fn:function(ref){
      var header = ref.header;
return [_vm._v(" "+_vm._s(_vm.$t(header.text))+" ")]}},{key:"top",fn:function(){return [_c('delete-dialog',{staticClass:"lvl2-elevation",attrs:{"dialogTitle":_vm.deleteDialogTitle,"dialogTarget":_vm.deleteDialogTarget,"showProgress":_vm.showDeleteProgress,"errorMessage":_vm.dialogErrorMessage},on:{"close-dialog":_vm.closeDelete,"confirm-dialog":_vm.deleteItem},model:{value:(_vm.showDeleteDialog),callback:function ($$v) {_vm.showDeleteDialog=$$v},expression:"showDeleteDialog"}}),_c('import-pbx-dialog',{staticClass:"lvl2-elevation",attrs:{"customerId":_vm.customer.uuid},on:{"close-dialog":function($event){_vm.showImportPbx=false},"import-pbx":function($event){_vm.showImportPbx=false}},model:{value:(_vm.showImportPbxDialog),callback:function ($$v) {_vm.showImportPbxDialog=$$v},expression:"showImportPbxDialog"}})]},proxy:true},{key:"item.actions",fn:function(ref){
      var item = ref.item;
return [_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.confirmDeleteItem(item)}}},[_vm._v(" fas fa-trash-alt ")]),(_vm.showDownloadItem(item))?_c('v-icon',{staticClass:"ml-3",attrs:{"small":""},on:{"click":function($event){return _vm.downloadItem(item)}}},[_vm._v(" fas fa-download ")]):_vm._e()]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }