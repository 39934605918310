var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-col',{style:(_vm.cssVariables)},[_c('v-row',[_c('v-data-table',{staticClass:"full-width data-table",attrs:{"item-key":"uuid","show-select":"","mobile-breakpoint":"0","headers":_vm.computedHeaders,"items":_vm.items,"options":_vm.options,"server-items-length":_vm.totalElements,"loading":_vm.loading && _vm.loadingConfigured,"no-data-text":_vm.$t('common.noDataAvailable'),"footer-props":{
          disablePagination: _vm.loading,
          itemsPerPageOptions: [10, 20, 50, 100],
          showFirstLastPage: true,
          firstIcon: 'first_page',
          prevIcon: 'chevron_left',
          nextIcon: 'chevron_right',
          lastIcon: 'last_page',
          itemsPerPageText: _vm.$t('common.rowsPerPage')
      }},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"header.data-table-select",fn:function(){return [_c('v-simple-checkbox',{attrs:{"color":"primary","ripple":false,"value":_vm.selectedAll},on:{"input":function($event){return _vm.selectAll($event)}}})]},proxy:true},{key:"header.firstname",fn:function(ref){
      var header = ref.header;
return [_c('v-combobox',{attrs:{"items":!_vm.firstname ? _vm.filterTypes : undefined,"item-text":"filterLabel","item-value":"filterValue","label":_vm.$t(header.text),"hide-no-data":"","hide-details":"","hide-selected":"","append-icon":"search","clear-icon":"close","solo":"","single-line":"","flat":"","clearable":"","placeholder":_vm.$t(header.text)},on:{"change":function ($event) { return _vm.firstname = $event; },"click:clear":function ($event) { return _vm.firstname = ''; }},model:{value:(_vm.firstname),callback:function ($$v) {_vm.firstname=$$v},expression:"firstname"}})]}},{key:"header.lastname",fn:function(ref){
      var header = ref.header;
return [_c('v-combobox',{attrs:{"items":!_vm.lastname ? _vm.filterTypes : undefined,"item-text":"filterLabel","item-value":"filterValue","label":_vm.$t(header.text),"hide-no-data":"","hide-details":"","hide-selected":"","append-icon":"search","clear-icon":"close","solo":"","single-line":"","flat":"","clearable":"","placeholder":_vm.$t(header.text)},on:{"change":function ($event) { return _vm.lastname = $event; },"click:clear":function ($event) { return _vm.lastname = ''; }},model:{value:(_vm.lastname),callback:function ($$v) {_vm.lastname=$$v},expression:"lastname"}})]}},{key:"header.email",fn:function(ref){
      var header = ref.header;
return [_c('v-combobox',{attrs:{"items":!_vm.email ? _vm.filterTypes : undefined,"item-text":"filterLabel","item-value":"filterValue","label":_vm.$t(header.text),"hide-no-data":"","hide-details":"","hide-selected":"","append-icon":"search","clear-icon":"close","solo":"","single-line":"","flat":"","clearable":"","placeholder":_vm.$t(header.text)},on:{"change":function ($event) { return _vm.email = $event; },"click:clear":function ($event) { return _vm.email = ''; }},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})]}},{key:"header.language",fn:function(ref){
      var header = ref.header;
return [_c('v-select',{attrs:{"items":_vm.languagesFilter,"item-text":"name","item-value":"localeCode","value":_vm.language,"label":_vm.$t(header.text),"append-icon":"search","clear-icon":"close","single-line":"","hide-details":"","flat":"","solo":"","clearable":"","placeholder":_vm.$t(header.text),"menuProps":{ auto: true, bottom: true, offsetY: true }},on:{"change":function ($event) { return _vm.language = $event; },"click:clear":function ($event) { return _vm.language = ''; }}})]}},{key:"header.normalizedNumber",fn:function(ref){
      var header = ref.header;
return [_c('v-text-field',{attrs:{"value":_vm.number,"label":_vm.$t(header.text),"append-icon":"search","clear-icon":"close","single-line":"","hide-details":"","flat":"","solo":"","clearable":"","placeholder":_vm.$t(header.text)},on:{"change":function ($event) { return _vm.number = $event; },"click:clear":function ($event) { return _vm.number = ''; }}})]}},{key:"header.extension",fn:function(ref){
      var header = ref.header;
return [_c('v-combobox',{attrs:{"items":!_vm.extension ? _vm.filterTypes : undefined,"item-text":"filterLabel","item-value":"filterValue","label":_vm.$t(header.text),"hide-no-data":"","hide-details":"","hide-selected":"","append-icon":"search","clear-icon":"close","solo":"","single-line":"","flat":"","clearable":"","placeholder":_vm.$t(header.text)},on:{"change":function ($event) { return _vm.extension = $event; },"click:clear":function ($event) { return _vm.extension = ''; }},model:{value:(_vm.extension),callback:function ($$v) {_vm.extension=$$v},expression:"extension"}})]}},{key:"header.role",fn:function(ref){
      var header = ref.header;
return [_c('v-select',{attrs:{"items":_vm.getRoles,"item-text":"displayName","item-value":"id","value":_vm.role,"label":_vm.$t(header.text),"append-icon":"search","clear-icon":"close","single-line":"","hide-details":"","flat":"","solo":"","clearable":"","placeholder":_vm.$t(header.text),"menuProps":{ auto: true, bottom: true, offsetY: true }},on:{"change":function ($event) { return _vm.role = $event; },"click:clear":function ($event) { return _vm.role = ''; }}})]}},{key:"header.trunkName",fn:function(ref){
      var header = ref.header;
return [_c('v-select',{attrs:{"items":_vm.getTrunks,"item-text":"name","item-value":"name","value":_vm.trunkName,"label":_vm.$t(header.text),"append-icon":"search","clear-icon":"close","single-line":"","hide-details":"","flat":"","solo":"","clearable":"","placeholder":_vm.$t(header.text),"menuProps":{ auto: true, bottom: true, offsetY: true }},on:{"change":function ($event) { return _vm.trunkName = $event; },"click:clear":function ($event) { return _vm.trunkName = ''; }}})]}},{key:"header.numberPorting",fn:function(ref){
      var header = ref.header;
return [_c('v-checkbox',{attrs:{"color":"primary","label":_vm.$t(header.text),"ripple":false,"hide-details":""},on:{"change":function($event){return _vm.togglePortingAll($event)}},model:{value:(_vm.selectedAllPorting),callback:function ($$v) {_vm.selectedAllPorting=$$v},expression:"selectedAllPorting"}})]}},{key:"header.macAddress",fn:function(ref){
      var header = ref.header;
return [_c('v-combobox',{attrs:{"items":!_vm.macAddress ? _vm.filterTypes : undefined,"item-text":"filterLabel","item-value":"filterValue","label":_vm.$t(header.text),"hide-no-data":"","hide-details":"","hide-selected":"","append-icon":"search","clear-icon":"close","solo":"","single-line":"","flat":"","clearable":"","placeholder":_vm.$t(header.text)},on:{"change":function ($event) { return _vm.macAddress = $event; },"click:clear":function ($event) { return _vm.macAddress = ''; }},model:{value:(_vm.macAddress),callback:function ($$v) {_vm.macAddress=$$v},expression:"macAddress"}})]}},{key:"header.ipAddress",fn:function(ref){
      var header = ref.header;
return [_c('v-combobox',{attrs:{"items":!_vm.ipAddress ? _vm.filterTypes : undefined,"item-text":"filterLabel","item-value":"filterValue","label":_vm.$t(header.text),"hide-no-data":"","hide-details":"","hide-selected":"","append-icon":"search","clear-icon":"close","solo":"","single-line":"","flat":"","clearable":"","placeholder":_vm.$t(header.text)},on:{"change":function ($event) { return _vm.ipAddress = $event; },"click:clear":function ($event) { return _vm.ipAddress = ''; }},model:{value:(_vm.ipAddress),callback:function ($$v) {_vm.ipAddress=$$v},expression:"ipAddress"}})]}},{key:"header.phoneType",fn:function(ref){
      var header = ref.header;
return [_c('v-select',{attrs:{"items":_vm.getDeviceTypes,"item-text":"deviceName","item-value":"deviceValue","value":_vm.phoneType,"label":_vm.$t(header.text),"append-icon":"search","clear-icon":"close","single-line":"","hide-details":"","flat":"","solo":"","clearable":"","placeholder":_vm.$t(header.text),"menuProps":{ auto: true, bottom: true, offsetY: true }},on:{"change":function ($event) { return _vm.phoneType = $event; },"click:clear":function ($event) { return _vm.phoneType = ''; }}})]}},{key:"item.data-table-select",fn:function(ref){
      var item = ref.item;
      var select = ref.select;
return [_c('v-simple-checkbox',{attrs:{"color":"primary","value":item.isSelected,"ripple":false,"disabled":_vm.selectedAll},on:{"input":function ($event) {select($event); _vm.editRow=''; _vm.validateRow($event, item);}}})]}},{key:"item.edit",fn:function(ref){
      var item = ref.item;
return [_c('v-btn',{class:item.isSelected ? 'visible' : 'invisible',attrs:{"icon":"","small":"","color":_vm.getColor(item)},on:{"click":function($event){return _vm.toggleEdit(item)}}},[_c('v-icon',[_vm._v("edit")])],1)]}},{key:"item.firstname",fn:function(ref){
      var item = ref.item;
return [(_vm.isInEditMode(item) && _vm.validateNow('firstName'))?_c('span',[_c('v-tooltip',{attrs:{"disabled":!_vm.getFieldErrorText(item, item.uuid, 'FIRST_NAME'),"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
return [_c('v-form',{ref:"firstName"},[_c('v-text-field',_vm._g({attrs:{"value":item.firstname,"label":item.firstname,"rules":_vm.getValidatorRules(item.uuid, 'FIRST_NAME'),"hide-details":"","outlined":"","solo":"","flat":"","dense":""},on:{"change":function ($event) {item.firstname = $event; _vm.editCell(item, item.uuid, 'FIRST_NAME');}}},on))],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.getFieldErrorText(item, item.uuid, 'FIRST_NAME')))])])],1):_c('span',[_vm._v(_vm._s(item.firstname))])]}},{key:"item.lastname",fn:function(ref){
      var item = ref.item;
return [(_vm.isInEditMode(item) && _vm.validateNow('lastName'))?_c('span',[_c('v-tooltip',{attrs:{"disabled":!_vm.getFieldErrorText(item, item.uuid, 'LAST_NAME'),"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
return [_c('v-form',{ref:"lastName"},[_c('v-text-field',_vm._g({attrs:{"value":item.lastname,"label":item.lastname,"rules":_vm.getValidatorRules(item.uuid, 'LAST_NAME'),"hide-details":"","outlined":"","solo":"","flat":"","dense":""},on:{"change":function ($event) {item.lastname = $event; _vm.editCell(item, item.uuid, 'LAST_NAME');}}},on))],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.getFieldErrorText(item, item.uuid, 'LAST_NAME')))])])],1):_c('span',[_vm._v(_vm._s(item.lastname))])]}},{key:"item.email",fn:function(ref){
      var item = ref.item;
return [(_vm.isInEditMode(item) && _vm.validateNow('email'))?_c('span',[_c('v-tooltip',{attrs:{"disabled":!_vm.getFieldErrorText(item, item.uuid, 'E-MAIL'),"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
return [_c('v-form',{ref:"email"},[_c('v-text-field',_vm._g({attrs:{"value":item.email,"label":item.email,"rules":_vm.getValidatorRules(item.uuid, 'E-MAIL'),"placeholder":"example@atos.net","hide-details":"","outlined":"","solo":"","flat":"","dense":""},on:{"change":function ($event) {item.email = $event; _vm.editCell(item, item.uuid, 'E-MAIL');}}},on))],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.getFieldErrorText(item, item.uuid, 'E-MAIL')))])])],1):_c('span',[_vm._v(_vm._s(item.email))])]}},{key:"item.language",fn:function(ref){
      var item = ref.item;
return [(_vm.isInEditMode(item))?_c('span',[_c('v-select',{attrs:{"items":_vm.languages,"item-text":"name","item-value":"localeCode","value":item.language,"outlined":"","solo":"","flat":"","dense":""},on:{"input":function ($event) {item.language = $event; _vm.editCell(item, item.uuid, 'LANGUAGE');}}})],1):_c('span',[_vm._v(_vm._s(_vm.getLanguageNameFromCode(item.language)))])]}},{key:"item.normalizedNumber",fn:function(ref){
      var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(item.primaryNumberText)+" ")])]}},{key:"item.extension",fn:function(ref){
      var item = ref.item;
return [(item.primaryExtension && _vm.isInEditMode(item) && _vm.validateNow('extension'))?_c('span',[_c('v-tooltip',{attrs:{"disabled":!_vm.getFieldErrorText(item, item.primaryExtension.uuid, 'EXTENSION'),"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
return [_c('v-form',{ref:"extension"},[_c('v-text-field',_vm._g({attrs:{"value":item.primaryExtension.number,"label":item.primaryExtension.number,"rules":_vm.getValidatorRules(item.primaryExtension.uuid, 'EXTENSION'),"hide-details":"","outlined":"","solo":"","flat":"","dense":""},on:{"change":function ($event) {item.primaryExtension.number = $event; _vm.editCell(item, item.primaryExtension.uuid, 'EXTENSION');}}},on))],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.getFieldErrorText(item, item.primaryExtension.uuid, 'EXTENSION')))])])],1):_c('span',[_vm._v(_vm._s(item.primaryExtension && item.primaryExtension.number))])]}},{key:"item.role",fn:function(ref){
      var item = ref.item;
return [(item.role && _vm.isInEditMode(item))?_c('span',[_c('v-select',{attrs:{"items":_vm.getRoles,"item-text":"displayName","item-value":"id","value":item.role,"outlined":"","solo":"","flat":"","dense":""},on:{"input":function ($event) {item.role = $event; _vm.editCell(item, item.uuid, 'ROLE');}}})],1):_c('span',[_vm._v(_vm._s(_vm.getRoleNameFromId(item.role)))])]}},{key:"item.trunkName",fn:function(ref){
      var item = ref.item;
return [(_vm.isInEditMode(item) && _vm.validateNow('trunkName'))?_c('span',[_c('v-tooltip',{attrs:{"disabled":!_vm.getFieldErrorText(item, item.uuid, 'TRUNK'),"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
return [_c('v-form',{ref:"trunkName"},[_c('v-select',_vm._g({attrs:{"items":_vm.getTrunks,"item-text":"name","item-value":"name","value":item.trunkName,"rules":_vm.getValidatorRules(item.uuid, 'TRUNK'),"outlined":"","solo":"","flat":"","dense":"","hide-details":"","menuProps":{ auto: true, bottom: true, offsetY: true }},on:{"input":function ($event) {item.trunkName = $event; _vm.editCell(item, item.uuid, 'TRUNK');}}},on))],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.getFieldErrorText(item, item.uuid, 'TRUNK')))])])],1):_c('span',[_vm._v(_vm._s(item.trunkName || ''))])]}},{key:"item.numberPorting",fn:function(ref){
      var item = ref.item;
return [(item.isSelected)?_c('span',[_c('v-simple-checkbox',{attrs:{"color":"primary","value":item.numberPorting || _vm.selectedAllPorting,"ripple":false,"dense":"","disabled":_vm.selectedAll && _vm.selectedAllPorting},on:{"input":function($event){return _vm.togglePorting($event, item)}}})],1):_c('span',[_vm._v(_vm._s(item.numberPorting))])]}},{key:"item.macAddress",fn:function(ref){
      var item = ref.item;
return [(item.primaryDevice && _vm.isInEditMode(item) && _vm.isHandledDeviceType(item) && _vm.validateNow('macAddress'))?_c('span',[_c('v-tooltip',{attrs:{"disabled":!_vm.getFieldErrorText(item, item.primaryDevice.uuid, 'MAC_ADDRESS'),"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
return [_c('v-form',{ref:"macAddress"},[_c('v-text-field',_vm._g({attrs:{"value":item.primaryDevice != '' ? item.primaryDevice.macAddress : '',"label":item.primaryDevice != '' ? item.primaryDevice.macAddress : '',"rules":_vm.getValidatorRules(item.primaryDevice.uuid, 'MAC_ADDRESS'),"hide-details":"","outlined":"","solo":"","flat":"","dense":""},on:{"change":function ($event) {item.primaryDevice.macAddress = $event; _vm.editCell(item, item.primaryDevice.uuid, 'MAC_ADDRESS');}}},on))],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.getFieldErrorText(item, item.primaryDevice.uuid, 'MAC_ADDRESS')))])])],1):_c('span',[_vm._v(_vm._s((_vm.isHandledDeviceType(item) && item.primaryDevice && item.primaryDevice.macAddress) || ''))])]}},{key:"item.ipAddress",fn:function(ref){
      var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(item.primaryDevice != '' ? item.primaryDevice.ipAddress : '')+" ")])]}},{key:"item.phoneType",fn:function(ref){
      var item = ref.item;
return [(item.primaryDevice && _vm.isInEditMode(item) && _vm.validateNow('phoneType'))?_c('span',[_c('v-tooltip',{attrs:{"disabled":!_vm.getFieldErrorText(item, item.primaryDevice.uuid, 'PHONE_TYPE'),"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
return [_c('div',_vm._g({attrs:{"multiple":""}},on),[_c('v-form',{ref:"phoneType"},[_c('v-select',{attrs:{"items":_vm.getDeviceTypes,"item-text":"deviceName","item-value":"deviceValue","value":item.primaryDevice.phoneType,"rules":_vm.getValidatorRules(item.primaryDevice.uuid, 'PHONE_TYPE'),"hide-details":"","outlined":"","solo":"","flat":"","dense":""},on:{"input":function ($event) {item.primaryDevice.phoneType = $event; _vm.editCell(item, item.primaryDevice.uuid, 'PHONE_TYPE');}}})],1)],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.getFieldErrorText(item, item.primaryDevice.uuid, 'PHONE_TYPE')))])])],1):_c('span',[_vm._v(_vm._s(item.primaryDevice && _vm.getDeviceName(item.primaryDevice.phoneType)))])]}}],null,true),model:{value:(_vm.migrationItems),callback:function ($$v) {_vm.migrationItems=$$v},expression:"migrationItems"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }