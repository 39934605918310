var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-col',{style:(_vm.cssVariables)},[_c('v-row',[_c('v-data-table',{staticClass:"full-width data-table",attrs:{"item-key":"uuid","show-select":"","mobile-breakpoint":"0","headers":_vm.headers,"items":_vm.items,"options":_vm.options,"server-items-length":_vm.totalElements,"loading":_vm.loading,"no-data-text":_vm.$t('common.noDataAvailable'),"footer-props":{
          disablePagination: _vm.loading,
          itemsPerPageOptions: [10, 20, 50, 100],
          showFirstLastPage: true,
          firstIcon: 'first_page',
          prevIcon: 'chevron_left',
          nextIcon: 'chevron_right',
          lastIcon: 'last_page',
          itemsPerPageText: _vm.$t('common.rowsPerPage')
      }},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"header.data-table-select",fn:function(){return [_c('v-simple-checkbox',{attrs:{"color":"primary","ripple":false,"value":_vm.selectedAll},on:{"input":function($event){return _vm.selectAll($event)}}})]},proxy:true},{key:"header.featureName",fn:function(ref){
      var header = ref.header;
return [_c('v-combobox',{attrs:{"items":!_vm.featureName ? _vm.filterTypes : undefined,"item-text":"filterLabel","item-value":"filterValue","label":_vm.$t(header.text),"hide-no-data":"","hide-details":"","hide-selected":"","append-icon":"search","clear-icon":"close","solo":"","single-line":"","flat":"","clearable":"","placeholder":_vm.$t(header.text),"disabled":true},on:{"change":function ($event) { return _vm.featureName = $event; },"click:clear":function ($event) { return _vm.featureName = ''; }},model:{value:(_vm.featureName),callback:function ($$v) {_vm.featureName=$$v},expression:"featureName"}})]}},{key:"header.groupName",fn:function(ref){
      var header = ref.header;
return [_c('v-combobox',{attrs:{"items":!_vm.groupName ? _vm.filterTypes : undefined,"item-text":"filterLabel","item-value":"filterValue","label":_vm.$t(header.text),"hide-no-data":"","hide-details":"","hide-selected":"","append-icon":"search","clear-icon":"close","solo":"","single-line":"","flat":"","clearable":"","placeholder":_vm.$t(header.text)},on:{"change":function ($event) { return _vm.groupName = $event; },"click:clear":function ($event) { return _vm.groupName = ''; }},model:{value:(_vm.groupName),callback:function ($$v) {_vm.groupName=$$v},expression:"groupName"}})]}},{key:"header.user",fn:function(ref){
      var header = ref.header;
return [_c('v-combobox',{attrs:{"items":!_vm.user ? _vm.filterTypes : undefined,"item-text":"filterLabel","item-value":"filterValue","label":_vm.$t(header.text),"hide-no-data":"","hide-details":"","hide-selected":"","append-icon":"search","clear-icon":"close","solo":"","single-line":"","flat":"","clearable":"","placeholder":_vm.$t(header.text)},on:{"change":function ($event) { return _vm.user = $event; },"click:clear":function ($event) { return _vm.user = ''; }},model:{value:(_vm.user),callback:function ($$v) {_vm.user=$$v},expression:"user"}})]}},{key:"header.pilotText",fn:function(ref){
      var header = ref.header;
return [_c('v-combobox',{attrs:{"items":!_vm.pilot ? _vm.filterTypes : undefined,"item-text":"filterLabel","item-value":"filterValue","label":_vm.$t(header.text),"hide-no-data":"","hide-details":"","hide-selected":"","append-icon":"search","clear-icon":"close","solo":"","single-line":"","flat":"","clearable":"","placeholder":_vm.$t(header.text)},on:{"change":function ($event) { return _vm.pilot = $event; },"click:clear":function ($event) { return _vm.pilot = ''; }},model:{value:(_vm.pilot),callback:function ($$v) {_vm.pilot=$$v},expression:"pilot"}})]}},{key:"header.huntGroupManagerEmail",fn:function(ref){
      var header = ref.header;
return [_c('span',[_vm._v(_vm._s(_vm.$t(header.text)))])]}},{key:"header.huntGroupExtension",fn:function(ref){
      var header = ref.header;
return [_vm._v(" "+_vm._s(_vm.$t(header.text))+" ")]}},{key:"header.membersTotal",fn:function(ref){
      var header = ref.header;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v(" "+_vm._s(_vm.$t(header.text))+" "),_c('v-icon',[_vm._v("info")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("stepper.callQueueMembersTooltip")))])])]}},{key:"item.data-table-select",fn:function(ref){
      var item = ref.item;
      var select = ref.select;
return [_c('v-simple-checkbox',{attrs:{"color":"primary","value":item.isSelected,"ripple":false,"disabled":_vm.selectedAll},on:{"input":function ($event) {select($event); _vm.editRow=''; _vm.validateRow($event, item);}}})]}},{key:"item.edit",fn:function(ref){
      var item = ref.item;
return [_c('v-btn',{class:item.isSelected ? 'visible' : 'invisible',attrs:{"icon":"","small":"","color":_vm.getColor(item)},on:{"click":function($event){return _vm.toggleEdit(item)}}},[_c('v-icon',[_vm._v("edit")])],1)]}},{key:"item.groupName",fn:function(ref){
      var item = ref.item;
return [(_vm.isInEditMode(item) && _vm.validateNow('groupName'))?_c('span',[_c('v-tooltip',{attrs:{"disabled":!_vm.getFieldErrorText(item, 'GROUP_NAME'),"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
return [_c('v-form',{ref:"groupName"},[_c('v-text-field',_vm._g({attrs:{"value":item.groupName,"label":item.groupName,"rules":_vm.getValidatorRules(item.uuid, 'GROUP_NAME'),"hide-details":"","outlined":"","solo":"","flat":"","dense":""},on:{"change":function ($event) {item.groupName = $event; _vm.editCell(item, item.uuid, 'GROUP_NAME');}}},on))],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.getFieldErrorText(item, 'GROUP_NAME')))])])],1):_c('span',[_vm._v(_vm._s(item.groupName))])]}},{key:"item.huntGroupManagerEmail",fn:function(ref){
      var item = ref.item;
return [(_vm.isInEditMode(item) && _vm.validateNow('managerEmail'))?_c('span',[_c('v-tooltip',{attrs:{"disabled":!_vm.getFieldErrorText(item, 'MANAGER'),"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
return [_c('v-form',{ref:"managerEmail"},[_c('v-text-field',_vm._g({attrs:{"value":item.huntGroupManagerEmail,"label":item.huntGroupManagerEmail,"rules":_vm.getValidatorRules(item.uuid, 'MANAGER'),"hide-details":"","outlined":"","solo":"","flat":"","dense":""},on:{"change":function ($event) {item.huntGroupManagerEmail = $event; _vm.editCell(item, item.uuid, 'MANAGER');}}},on))],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.getFieldErrorText(item, 'MANAGER')))])])],1):_c('span',[_vm._v(_vm._s(item.huntGroupManagerEmail))])]}},{key:"item.huntGroupExtension",fn:function(ref){
      var item = ref.item;
return [(_vm.isInEditMode(item) && _vm.hasNotBeenMigrated(item))?_c('span',[_c('v-tooltip',{attrs:{"disabled":!_vm.getFieldErrorText(item, 'EXTENSION'),"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
return [_c('v-text-field',_vm._g({attrs:{"value":item.huntGroupExtension,"label":item.huntGroupExtension,"rules":_vm.getValidatorRules(item.uuid, 'EXTENSION'),"hide-details":"","outlined":"","solo":"","flat":"","dense":""},on:{"change":function ($event) {item.huntGroupExtension = $event; _vm.editCell(item, item.uuid, 'EXTENSION');}}},on))]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.getFieldErrorText(item, 'EXTENSION')))])])],1):_c('span',[_vm._v(_vm._s(item.huntGroupExtension))])]}}],null,true),model:{value:(_vm.migrationItems),callback:function ($$v) {_vm.migrationItems=$$v},expression:"migrationItems"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }