<template>
  <v-container fluid class="umt-container">
    <!-- <legal-dialog
      :showDialog="showDialog"
      :legalContent="legalContent"
      @close-dialog="showDialog=false"/> -->
    <v-row no-gutters class="fill-height">
      <v-col class="profile-col">
        <app-logo/>
        <uo-account :companyName='companyName' :phoneNumber='mainNumber'/>
      </v-col>
      <v-col class="about-col">
        <v-row no-gutters class="about-header">
          <p class="about-text font-weight-bold">{{ $t("app.about") }}</p>
        </v-row>
        <v-row no-gutters>
          <v-col>
            <v-row no-gutters class="about-version flex-column">
                <div class="mb-6"> {{ appVersion }}</div>
                <div class="mb-6"> {{ appCopyright }}</div>
                <div class="mb-6"> {{ appAllRights }}</div>
            </v-row>
           
            <v-row no-gutters class="close-and-legal flex-column">
              <v-col>
                <v-btn class="button-text-18 no-text-transform"
                  depressed dark color="primary"
                  @click="onClickClose">
                  {{ $t("common.close") }}
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import { mapState } from 'vuex';
  import AppLogo from "../components/appLogo";
  import UoAccount from "../components/uoAccount";
  import router from '../router';

  export default {
    name: 'About',
    components: {
      AppLogo,
      UoAccount
    },
    data() {
      return {
        legalContent: '',
        showDialog: false
      }
    },
    methods: {
      onClickClose() {
          this.$log.debug('[UOMTApp] Route to Home');
          router.push('/');
      }
    },
    computed: {
      ...mapState(['loggedUser', 'appNameSplitted']),
      companyName() {
        return this.loggedUser && this.loggedUser.account && this.loggedUser.account.company;
      },
      mainNumber() {
        return this.loggedUser && this.loggedUser.user && this.loggedUser.user.customers && this.loggedUser.user.customers.length && this.loggedUser.user.customers[0].mainNumber;
      },
      appVersion() {
        return `Version ${process.env.VUE_APP_VERSION}`;
      },
      appCopyright() {
        return `${this.$t("app.copyright")} ${new Date().getFullYear()}`;
      },
      appAllRights() {
        return this.$t("app.allRights");
      },
      appNameObject() {
        return this.appNameSplitted;
      }
    }
  }
</script>

<style scoped  lang="scss">
  .about-col {
    padding: 7rem 4rem;
    background-color: #F3F3F3;
    color: #0D65A3
  }
  .about-header {
    font-size: 24px;
    padding: 0 3rem;
    margin-bottom: 5rem;
  }
  .about-appname {
    padding: 0.5rem 3rem;
  }
  .about-version {
    color: #263038;
    font: normal normal bold 16px/19px Roboto;
    padding: 0.5rem 3rem;
    margin-bottom: 5rem;
  }
  .close-and-legal {
    padding: 0 3rem;
  }
  .legal-links {
    font: normal normal normal 16px/19px Roboto;
    a {
        display: block;
        text-decoration: underline;
        margin-bottom: 1.375rem;
    }
  }
</style>
