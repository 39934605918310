<template>
    <v-row no-gutters class="fill-height">
      <v-col>
        <md-dialog-confirm
          :md-active.sync="showUnauthorized"
          :md-title="unauthorizedDialogTitle"
          :md-content="unauthorizedDialogText"
          @md-confirm="onConfirmErrorMessage"
        />

        <div class="banner lvl1-elevation">
          <v-banner class="rounded-xl" outlined color="#F0F0F0" single-line :width="migrationReportAvailable ? '800' : '600'">
            <v-tabs background-color="transparent" height="30" slider-size="4">
              <v-tab class="nav-text mx-auto no-text-transform" @click="$vuetify.goTo(0)">{{ $t("sources.sources") }}</v-tab>
              <v-divider class="mx-4" vertical></v-divider>
              <v-tab class="nav-text mx-13 no-text-transform" @click="$vuetify.goTo('#customer-migration')">{{ $t("configureMigrate.configureMigrate") }}</v-tab>
              <v-divider v-if='migrationReportAvailable' class="mx-4" vertical></v-divider>
              <v-tab v-if='migrationReportAvailable' class="nav-text mx-13 no-text-transform" @click="$vuetify.goTo('#migration-report')">{{ $t("migrationReport.label") }}</v-tab>
            </v-tabs>
          </v-banner>
        </div>

        <customer-pbxes />
        <customer-migration id='customer-migration'/>
        <migration-report v-if='migrationReportAvailable' id='migration-report'/>

        <v-container fluid class="reports-container">
          <v-row no-gutters justify="end">
            <v-col sm="auto">
							<navigationBar :customerId='customer.uuid'></navigationBar>
						</v-col>
          </v-row>
          <v-row no-gutters>
						<v-col class="pa-0">
              <!--- Consistency Report --->
              <v-card v-if='getConsistencyReport && getConsistencyReport.length' class="report-card" elevation="0">
                <v-card-title class="text-bold-20 px-0">{{ $t('customerView.spreadsheetInconsistenciesLabel') }}</v-card-title>
                 <v-virtual-scroll
                  :items="getConsistencyReport"
                  max-height="300" item-height="20"
                >
                  <template v-slot:default="{ index, item }">
                    <v-list-item :key="item.index">
                      <v-list-item-content>
                        <v-list-item-title class="report-text">
                            {{ index + 1 }}.
                            <span v-if="!!item.uuid">uuid {{ item.uuid }} in {{ item.tab }} tab: {{ item.message }}</span>
                            <span v-else>{{ item.message }} </span>
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                </v-virtual-scroll>
              </v-card>
              <!--- Migration Report --->
						</v-col>
					</v-row>
        </v-container>
      </v-col>
    </v-row>
</template>

<script>
  import { mapState } from 'vuex';
  import store from '../store';
  import * as RestApiHandler from '../common/restApiHandler';

  import CustomerPbxes from '@/components/customerPbxes';
  import CustomerMigration from '@/components/customerMigration';
  import MigrationReport from '@/components/migrationReport';
  import NavigationBar from '@/components/NavigationBar.vue';

  export default {
    name: 'CustomerView',
    components: {
      CustomerPbxes,
      CustomerMigration,
      MigrationReport,
      NavigationBar
    },
    data: () => ({
      unauthorizedDialogText: 'Your user is not authorized to execute the operation.<br>You need to login again.'
      }),

    computed: {
      ...mapState(['customer', 'unauthorized', 'consistencyReport', 'migrationReport']),
      unauthorizedDialogTitle() {
        return this.unauthorized.request === 'importPbx' ? 'Import PBX error:' : 'Error';
      },
      getConsistencyReport() {
        return this.consistencyReport;
      },
      migrationReportAvailable() {
        return this.migrationReport && this.migrationReport.length;
      },
      showUnauthorized: {
        get () {
          return !!this.unauthorized;
        },
        set (value) {
          store.commit('setUnauthorized', value)
        }
      }
    },
    methods: {
      onConfirmErrorMessage() {
        if (this.unauthorized) {
          RestApiHandler.logout();
        }
      }
    }
  }
</script>

<style scoped>
  .banner {
    position: sticky;
    top: 6.7rem; /* required */
    display: flex;
    justify-content: center;
  }
  .nav-text {
    font: normal normal 500 18px/21px Roboto;
    letter-spacing: 0px;
  }
  .reports-container {
		padding: 0;
    margin-top: 1.5rem;
  }
</style>
