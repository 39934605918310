import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: 'fa',
  },
  theme: {
    themes: {
      light: {
        primary: '#0D65A3',
        error: '#E23434',
        success: '#1ABC9C',
        light: '#F2F2f2',
        medium: '#657786',
        gray: '#909090',
        dark: '#34495E',
        white: '#FFFFFF'
      },
      dark: {
        primary: '#0D65A3',
        error: '#E23434',
        success: '#1ABC9C',
        light: '#F2F2f2',
        medium: '#657786',
        gray: '#909090',
        dark: '#34495E',
        white: '#FFFFFF'
      }
    },
  }
});
