<template>
  <v-container fluid class="umt-container login-container">
    <v-row no-gutters>
      <v-col class="composite-title">
        <div class="composite-name">
          <v-img 
            alt="Composite Name Login"
            contain
            :src="require('../assets/composite-name-login.png')"
            width="350"
          />
        </div>
        <div class="composite-subtitle">
            User Migration Tool
        </div>
      </v-col>
    </v-row>
    <v-row no-gutters justify="end" class="login-content">
      <v-col sm="3" align-self="center">
        <div class="login-header">{{ $t("loginPage.loginHeader") }}</div>
        <div no-gutters class="login-description">{{ $t("loginPage.loginDesc") }}</div>
        <v-select
          v-model="selectedEnv"
          :items="environments" item-text='name' item-value="id"
          :label="$t('loginPage.EnvironmentLabel')"
          solo dense
        ></v-select>
        <v-btn class="text-button-18 no-text-transform"
          :light="!!selectedEnv" :dark="!selectedEnv"
          :disabled="!selectedEnv"
          block depressed
          color="light"
          @click="onLogin">
          {{ $t("loginPage.loginButtonLabel") }}
        </v-btn>
         <div no-gutters class="login-error">{{ errorDescription && $t(`loginPage.error.${errorDescription}`) }}</div>
      </v-col>
    </v-row>
    <v-row no-gutters justify="end">
      <v-col sm="auto" class="bottom-logo">
        <v-img
          alt="Atos Login"
          contain
          :src="require('../assets/atos-logo-login.png')"
          width="270"
        /> 
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import * as RestApiHandler from '../common/restApiHandler';
  import router from '../router';
  import { mapState } from 'vuex';
  import store from '../store';

  export default {
    name: 'Logon',
    computed: {
      ...mapState(['appNameSplitted', 'loggedUser', 'environments']),
      showLoginError() {
        return this.error && this.error.status;
      },
      errorDescription() {
        return this.error && this.error.description;
      }
    },
    data: () => ({
      selectedEnv: 'production',
      error: {}
    }),
    methods: {
      async onLogin() {
        try {
          this.error = {};
          RestApiHandler.logout();
          localStorage.selectedEnvironment = this.selectedEnv;
          const result = await RestApiHandler.logon(this.selectedEnv);
          if (result && result.redirectUrl) {
            this.$log.debug(`[Logon] doRCLogon redirectUrl: ${result.redirectUrl}`);
            window.location.assign(result.redirectUrl);
          } else {
            this.$log.debug('[Logon] doRCLogon invalid response: ', result);
          }
        } catch (err) {
          this.$log.debug('[Logon] doRCLogon error:', err);
        }
      },
      async fetchEnvironments() {
        await store.dispatch('getEnvironments');
        if (localStorage.selectedEnvironment && this.environments.filter(elem => elem.id === localStorage.selectedEnvironment).length > 0) {
          this.selectedEnv = localStorage.selectedEnvironment;
        }
      },
    },
    created() {
      this.fetchEnvironments();
      this.$log.debug(`[Logon] created: ${window.location}`);
      this.error.status = this.$route && this.$route.query && this.$route.query.error;
      this.error.description = this.$route && this.$route.query && this.$route.query.error_description && this.$route.query.error_description.split(':')[0];
    },
    mounted() {
      if (this.loggedUser && this.loggedUser.account) {
        router.push('/');
      }
    }
  }
</script>
<style scoped>
  .composite-title {
    height: 230px;
    min-width: 550px;
  }

  .composite-name {
    margin-bottom: 30px; 
  }

  .composite-subtitle {
     font: normal normal normal 50px/60px Verdana;
     border-top: solid 2px white;
     padding-top: 60px;
  }

  .bottom-logo {
    height: 120px;
  }

  .login-content { 
    padding: 2rem 0;
    height: calc(100% - 350px); 

  }

  .login-header {
    color: white;
    font: normal normal 800 40px/50px Montserrat;
    letter-spacing: 5.4px;
    opacity: 1;
    margin-bottom: 1.125rem;
  }
  .login-description {
    font: normal normal normal 18px/21px Roboto;
    letter-spacing: 0px;
    color: white;
    opacity: 1;
    margin-bottom: 3.375rem;
  }
  .login-error {
    font: normal normal 18px/21px Roboto;
    letter-spacing: 0px;
    color: rgb(255, 100, 128);
    margin-top: 1.125rem;
  }
</style>
