<template>
  <v-col :style="cssVariables">
    <v-row>
      <v-data-table
        item-key="uuid"
        v-model="migrationItems"
        show-select
        mobile-breakpoint=0
        :headers="computedHeaders"
        :items="items"
        :options.sync="options"
        :server-items-length="totalElements"
        :loading="loading && loadingConfigured"
        :no-data-text = "$t('common.noDataAvailable')"
        :footer-props="{
            disablePagination: loading,
            itemsPerPageOptions: [10, 20, 50, 100],
            showFirstLastPage: true,
            firstIcon: 'first_page',
            prevIcon: 'chevron_left',
            nextIcon: 'chevron_right',
            lastIcon: 'last_page',
            itemsPerPageText: $t('common.rowsPerPage')
        }"
        class="full-width data-table"
      >
         <template v-slot:[`header.data-table-select`]>
            <v-simple-checkbox
              color="primary" :ripple="false"
              :value="selectedAll"
              @input="selectAll($event)"
            ></v-simple-checkbox>
        </template>
        <template v-slot:[`header.firstname`]="{ header }">
          <v-combobox
              v-model="firstname"
              :items="!firstname ? filterTypes : undefined"
              item-text="filterLabel"
              item-value="filterValue"
              :label="$t(header.text)"
              hide-no-data hide-details hide-selected
              append-icon="search"
              clear-icon="close"
              solo single-line flat clearable 
              @change="$event => firstname = $event"
              @click:clear="$event => firstname = ''"
              :placeholder="$t(header.text)"
          ></v-combobox>
        </template>
        <template v-slot:[`header.lastname`]="{ header }">
          <v-combobox
              v-model="lastname"
              :items="!lastname ? filterTypes : undefined"
              item-text="filterLabel"
              item-value="filterValue"
              :label="$t(header.text)"
              hide-no-data hide-details hide-selected
              append-icon="search"
              clear-icon="close"
              solo single-line flat clearable 
              @change="$event => lastname = $event"
              @click:clear="$event => lastname = ''"
              :placeholder="$t(header.text)"
          ></v-combobox>
        </template>
        <template v-slot:[`header.email`]="{ header }">
          <v-combobox
              v-model="email"
              :items="!email ? filterTypes : undefined"
              item-text="filterLabel"
              item-value="filterValue"
              :label="$t(header.text)"
              hide-no-data hide-details hide-selected
              append-icon="search"
              clear-icon="close"
              solo single-line flat clearable 
              @change="$event => email = $event"
              @click:clear="$event => email = ''"
              :placeholder="$t(header.text)"
            ></v-combobox>
        </template>
        <template v-slot:[`header.language`]="{ header }">
          <v-select
            :items="languagesFilter"
            item-text="name"
            item-value="localeCode"
            :value="language"
            :label="$t(header.text)"
            @change="$event => language = $event"
            @click:clear="$event => language = ''"
            append-icon="search"
            clear-icon="close"
            single-line hide-details
            flat solo clearable
            :placeholder="$t(header.text)"
            :menuProps="{ auto: true, bottom: true, offsetY: true }"
          ></v-select>
        </template>
        <template v-slot:[`header.normalizedNumber`]="{ header }">
          <v-text-field
            :value="number"
            @change="$event => number = $event"
            @click:clear="$event => number = ''"
            :label="$t(header.text)"
            append-icon="search"
            clear-icon="close"
            single-line hide-details
            flat solo clearable
            :placeholder="$t(header.text)"
          ></v-text-field>
        </template>
        <template v-slot:[`header.extension`]="{ header }">
          <v-combobox
              v-model="extension"
              :items="!extension ? filterTypes : undefined"
              item-text="filterLabel"
              item-value="filterValue"
              :label="$t(header.text)"
              hide-no-data hide-details hide-selected
              append-icon="search"
              clear-icon="close"
              solo single-line flat clearable 
              @change="$event => extension = $event"
              @click:clear="$event => extension = ''"
              :placeholder="$t(header.text)"
          ></v-combobox>
        </template>
        <template v-slot:[`header.role`]="{ header }">
          <v-select
            :items="getRoles"
            item-text="displayName"
            item-value="id"
            :value="role"
            :label="$t(header.text)"
            @change="$event => role = $event"
            @click:clear="$event => role = ''"
            append-icon="search"
            clear-icon="close"
            single-line hide-details
            flat solo clearable
            :placeholder="$t(header.text)"
            :menuProps="{ auto: true, bottom: true, offsetY: true }"
          ></v-select>
        </template>
        <template v-slot:[`header.trunkName`]="{ header }">
          <v-select
            :items="getTrunks"
            item-text="name"
            item-value="name"
            :value="trunkName"
            :label="$t(header.text)"
            @change="$event => trunkName = $event"
            @click:clear="$event => trunkName = ''"
            append-icon="search"
            clear-icon="close"
            single-line hide-details
            flat solo clearable
            :placeholder="$t(header.text)"
            :menuProps="{ auto: true, bottom: true, offsetY: true }"
          ></v-select>
        </template>
        <template v-slot:[`header.numberPorting`]="{ header }">
          <v-checkbox
            color="primary"
            :label= $t(header.text)
            v-model="selectedAllPorting"
            @change="togglePortingAll($event)"
            :ripple="false" hide-details
          ></v-checkbox>
        </template>
        <template v-slot:[`header.macAddress`]="{ header }">
          <v-combobox
              v-model="macAddress"
              :items="!macAddress ? filterTypes : undefined"
              item-text="filterLabel"
              item-value="filterValue"
              :label="$t(header.text)"
              hide-no-data hide-details hide-selected
              append-icon="search"
              clear-icon="close"
              solo single-line flat clearable 
              @change="$event => macAddress = $event"
              @click:clear="$event => macAddress = ''"
              :placeholder="$t(header.text)"
          ></v-combobox>
        </template>
        <template v-slot:[`header.ipAddress`]="{ header }">
          <v-combobox
              v-model="ipAddress"
              :items="!ipAddress ? filterTypes : undefined"
              item-text="filterLabel"
              item-value="filterValue"
              :label="$t(header.text)"
              hide-no-data hide-details hide-selected
              append-icon="search"
              clear-icon="close"
              solo single-line flat clearable 
              @change="$event => ipAddress = $event"
              @click:clear="$event => ipAddress = ''"
              :placeholder="$t(header.text)"
          ></v-combobox>
        </template>
        <template v-slot:[`header.phoneType`]="{ header }">
          <v-select
            :items="getDeviceTypes"
            item-text="deviceName"
            item-value="deviceValue"
            :value="phoneType"
            :label="$t(header.text)"
            @change="$event => phoneType = $event"
            @click:clear="$event => phoneType = ''"
            append-icon="search"
            clear-icon="close"
            single-line hide-details
            flat solo clearable
            :placeholder="$t(header.text)"
            :menuProps="{ auto: true, bottom: true, offsetY: true }"
          ></v-select>
        </template>
        <template v-slot:[`item.data-table-select`]="{ item, select }">
            <v-simple-checkbox
              color="primary"
              :value="item.isSelected"
              @input="$event => {select($event); editRow=''; validateRow($event, item);}"
              :ripple="false"
              :disabled="selectedAll"
            ></v-simple-checkbox>
        </template>
        <template v-slot:[`item.edit`]="{ item }">
            <v-btn
              icon small
              :color="getColor(item)"
              v-bind:class="item.isSelected ? 'visible' : 'invisible'"
              @click="toggleEdit(item)"
            >
              <v-icon>edit</v-icon>
            </v-btn>
        </template>
        <template v-slot:[`item.firstname`]="{ item }" >
            <span v-if="isInEditMode(item) && validateNow('firstName')">
              <v-tooltip :disabled="!getFieldErrorText(item, item.uuid, 'FIRST_NAME')" top>
                <template v-slot:activator="{ on }">
                  <v-form ref="firstName">
                    <v-text-field
                      :value="item.firstname"
                      :label="item.firstname"
                      @change="$event => {item.firstname = $event; editCell(item, item.uuid, 'FIRST_NAME');}"
                      :rules="getValidatorRules(item.uuid, 'FIRST_NAME')"
                      hide-details
                      outlined solo flat dense
                      v-on="on"
                    ></v-text-field>
                  </v-form>
                </template>
                <span>{{getFieldErrorText(item, item.uuid, 'FIRST_NAME')}}</span>               
              </v-tooltip>
            </span>
            <span v-else>{{item.firstname}}</span>
        </template>
        <template v-slot:[`item.lastname`]="{ item }">
            <span v-if="isInEditMode(item) && validateNow('lastName')">
              <v-tooltip :disabled="!getFieldErrorText(item, item.uuid, 'LAST_NAME')" top>
                <template v-slot:activator="{ on }">
                  <v-form ref="lastName">
                    <v-text-field
                      :value="item.lastname"
                      :label="item.lastname"
                      :rules="getValidatorRules(item.uuid, 'LAST_NAME')"
                      @change="$event => {item.lastname = $event; editCell(item, item.uuid, 'LAST_NAME');}"  
                      hide-details
                      outlined solo flat dense
                      v-on="on"
                    ></v-text-field>
                  </v-form>
                </template>
                <span>{{getFieldErrorText(item, item.uuid, 'LAST_NAME')}}</span>                              
              </v-tooltip>
            </span>
            <span v-else>{{item.lastname}}</span>
        </template>
        <template v-slot:[`item.email`]="{ item }">
            <span v-if="isInEditMode(item) && validateNow('email')">
              <v-tooltip :disabled="!getFieldErrorText(item, item.uuid, 'E-MAIL')" top>
                <template v-slot:activator="{ on }">
                  <v-form ref="email">
                    <v-text-field
                      :value="item.email"
                      :label="item.email"
                      @change="$event => {item.email = $event; editCell(item, item.uuid, 'E-MAIL');}"                    
                      :rules="getValidatorRules(item.uuid, 'E-MAIL')"
                      placeholder="example@atos.net"                      
                      hide-details
                      outlined solo flat dense
                      v-on="on"
                    ></v-text-field>
                  </v-form>
                </template>                
                <span>{{getFieldErrorText(item, item.uuid, 'E-MAIL')}}</span>
              </v-tooltip>
            </span>
            <span v-else>{{item.email}}</span>
        </template>
        <template v-slot:[`item.language`]="{ item }">
            <span v-if="isInEditMode(item)">
                <v-select
                  :items="languages"
                  item-text="name"
                  item-value="localeCode"
                  :value="item.language"
                  @input="$event => {item.language = $event; editCell(item, item.uuid, 'LANGUAGE');}"
                  outlined solo flat dense
                ></v-select>
            </span>
            <span v-else>{{getLanguageNameFromCode(item.language)}}</span>
        </template>
        <template v-slot:[`item.normalizedNumber`]="{ item }">
            <span>
              {{item.primaryNumberText}}
            </span>
        </template>
        <template v-slot:[`item.extension`]="{ item }">
            <span v-if="item.primaryExtension && isInEditMode(item) && validateNow('extension')">
              <v-tooltip :disabled="!getFieldErrorText(item, item.primaryExtension.uuid, 'EXTENSION')" top>
                <template v-slot:activator="{ on }">
                  <v-form ref="extension">
                    <v-text-field
                      :value="item.primaryExtension.number"
                      :label="item.primaryExtension.number"
                      @change="$event => {item.primaryExtension.number = $event; editCell(item, item.primaryExtension.uuid, 'EXTENSION');}" 
                      :rules="getValidatorRules(item.primaryExtension.uuid, 'EXTENSION')"
                      v-on="on"
                      hide-details
                      outlined solo flat dense
                    ></v-text-field>
                  </v-form>
                </template> 
                <span>{{getFieldErrorText(item, item.primaryExtension.uuid, 'EXTENSION')}}</span>
              </v-tooltip>
            </span>
            <span v-else>{{item.primaryExtension && item.primaryExtension.number}}</span>
        </template>
        <template v-slot:[`item.role`]="{ item }">
            <span v-if="item.role && isInEditMode(item)">
                <v-select
                  :items="getRoles"
                  item-text="displayName"
                  item-value="id"
                  :value="item.role"
                  @input="$event => {item.role = $event; editCell(item, item.uuid, 'ROLE');}"
                  outlined solo flat dense
                ></v-select>              
            </span>
            <span v-else>{{getRoleNameFromId(item.role)}}</span>
        </template>
        <template v-slot:[`item.trunkName`]="{ item }">
            <span v-if="isInEditMode(item) && validateNow('trunkName')">
              <v-tooltip :disabled="!getFieldErrorText(item, item.uuid, 'TRUNK')" top>
                <template v-slot:activator="{ on }">
                  <v-form ref="trunkName">
                    <v-select
                      :items="getTrunks"
                      item-text="name"
                      item-value="name"
                      :value="item.trunkName"
                      @input="$event => {item.trunkName = $event; editCell(item, item.uuid, 'TRUNK');}"
                      :rules="getValidatorRules(item.uuid, 'TRUNK')"
                      v-on="on"
                      outlined solo flat dense
                      hide-details
                      :menuProps="{ auto: true, bottom: true, offsetY: true }"
                    ></v-select>
                  </v-form>
                </template>
                <span>{{getFieldErrorText(item, item.uuid, 'TRUNK')}}</span>
              </v-tooltip>
            </span>
            <span v-else>{{item.trunkName || ''}}</span>
        </template>
        <template v-slot:[`item.numberPorting`]="{ item }">
            <span v-if="item.isSelected">
              <v-simple-checkbox
                color="primary"
                :value="item.numberPorting || selectedAllPorting"
                @input="togglePorting($event, item)"
                :ripple="false" dense
                :disabled="selectedAll && selectedAllPorting"
              ></v-simple-checkbox>
            </span>
            <span v-else>{{item.numberPorting}}</span>
        </template>
        <template v-slot:[`item.macAddress`]="{ item }">
            <span v-if="item.primaryDevice && isInEditMode(item) && isHandledDeviceType(item) && validateNow('macAddress')">
              <v-tooltip :disabled="!getFieldErrorText(item, item.primaryDevice.uuid, 'MAC_ADDRESS')" top>
                <template v-slot:activator="{ on }">
                  <v-form ref="macAddress">
                    <v-text-field
                      :value="item.primaryDevice != '' ? item.primaryDevice.macAddress : ''"
                      :label="item.primaryDevice != '' ? item.primaryDevice.macAddress : ''"
                      @change="$event => {item.primaryDevice.macAddress = $event; editCell(item, item.primaryDevice.uuid, 'MAC_ADDRESS');}" 
                      :rules="getValidatorRules(item.primaryDevice.uuid, 'MAC_ADDRESS')"
                      hide-details
                      outlined solo flat dense
                      v-on="on"
                    ></v-text-field>
                  </v-form>
                </template>
                <span>{{getFieldErrorText(item, item.primaryDevice.uuid, 'MAC_ADDRESS')}}</span>
              </v-tooltip>
            </span>
            <span v-else>{{(isHandledDeviceType(item) && item.primaryDevice && item.primaryDevice.macAddress) || ''}}</span>
        </template>
        <template v-slot:[`item.ipAddress`]="{ item }">
            <span>
              {{item.primaryDevice != '' ? item.primaryDevice.ipAddress : ''}}
            </span>
        </template>
        <template v-slot:[`item.phoneType`]="{ item }">
            <span v-if="item.primaryDevice && isInEditMode(item) && validateNow('phoneType')">
              <v-tooltip :disabled="!getFieldErrorText(item, item.primaryDevice.uuid, 'PHONE_TYPE')" top>
                <template v-slot:activator="{ on }">
                  <div multiple v-on="on">
                    <v-form ref="phoneType">
                      <v-select
                        :items="getDeviceTypes"
                        item-text="deviceName"
                        item-value="deviceValue"
                        :value="item.primaryDevice.phoneType"
                        @input="$event => {item.primaryDevice.phoneType = $event; editCell(item, item.primaryDevice.uuid, 'PHONE_TYPE');}"
                        :rules="getValidatorRules(item.primaryDevice.uuid, 'PHONE_TYPE')"
                        hide-details
                        outlined solo flat dense
                      ></v-select>
                    </v-form>
                  </div>
                </template>
                <span>{{getFieldErrorText(item, item.primaryDevice.uuid, 'PHONE_TYPE')}}</span>
              </v-tooltip>
            </span>
            <span v-else>{{item.primaryDevice && getDeviceName(item.primaryDevice.phoneType)}}</span>
        </template>
      </v-data-table>
    </v-row>
  </v-col>
</template>
<script>
  import { mapState, mapGetters } from 'vuex';
  import store from '../store';
  import Utils from '../common/utils';

  export default {
    name: 'ConfigurationUsers',
     data() {
      return {
        selected: [],
        loading: false,
        loadingConfigured: false,
        devices: [],
        /**
         * The width of the headers has been calculated based on their label and potential content
         */
        commonHeaders:[
          { value: 'edit', align: 'start', width: '1', sortable: false, cellClass: "data-table-edit" },
          { text: 'stepper.firstName', value: 'firstname', align: 'start', sortable: false,
            class: 'data-table-header required-header configuration-users-headers', cellClass: 'data-table-cell required-input'
          },
          { text: 'stepper.lastName', value: 'lastname', align: 'start', sortable: false,
            class: 'data-table-header required-header configuration-users-headers', cellClass: 'data-table-cell required-input'
          },
          { text: 'configureMigrate.email', value: 'email', align: 'start', sortable: false,
            class: 'data-table-header required-header configuration-users-headers', cellClass: 'data-table-cell required-input'
          },
          { text: 'stepper.language', value: 'language', align: 'start', sortable: false,
            class: 'data-table-header configuration-users-headers', cellClass: 'data-table-cell'
          },
          { text: 'stepper.number', value: 'normalizedNumber', align: 'start', sortable: false,
            class: 'data-table-header configuration-users-headers', cellClass: 'data-table-cell required-input'
          }
        ],
        extensionHeader:[
          { text: 'stepper.extension', value: 'extension', align: 'start', sortable: false,
            class: 'data-table-header configuration-users-headers', cellClass: 'data-table-cell'
          }
        ],
        numberPortingHeader:[
          { text: 'stepper.numberPorting', value: 'numberPorting', align: 'center', sortable: false,
            class: 'data-table-header configuration-users-headers', cellClass: 'data-table-cell'
          }
        ],
        deviceHeaders:[
          { text: 'stepper.macAddress', value: 'macAddress', align: 'start', sortable: false,
            class: 'data-table-header configuration-users-headers', cellClass: 'data-table-cell'
          },
          { text: 'stepper.ipAddress', value: 'ipAddress', align: 'start', sortable: false,
            class: 'data-table-header configuration-users-headers', cellClass: 'data-table-cell'
          },
          { text: 'stepper.phoneType', value: 'phoneType', align: 'start', sortable: false,
            class: 'data-table-header configuration-users-headers', cellClass: 'data-table-cell required-input'
          }
        ],
        roleHeader:[
          { text: 'stepper.role', value: 'role', align: 'start', sortable: false,
            class: 'data-table-header configuration-users-headers', cellClass: 'data-table-cell'
          }
        ],
        trunkHeaderOptional:[
          { text: 'stepper.trunk', value: 'trunkName', align: 'start', sortable: false,
            class: 'data-table-header configuration-users-headers', cellClass: 'data-table-cell'
          }
        ],
        trunkHeaderRequired:[
          { text: 'stepper.trunk', value: 'trunkName', align: 'start', sortable: false,
            class: 'data-table-header required-header configuration-users-headers', cellClass: 'data-table-cell'
          }
        ],
        resizeTimer: 0,
        computedHeaders: [],
        validators: {
          'FIRST_NAME': [this.isRequired, this.isXSSsanitized],
          'LAST_NAME': [this.isRequired, this.isXSSsanitized],
          'E-MAIL': [this.isRequired, this.isEmail],
          'LANGUAGE': [],
          'EXTENSION': [this.isDigitsOnlyOrEmpty],
          'NUMBER_PORTING': [],
          'MAC_ADDRESS': [this.isMACaddress],
          'PHONE_TYPE': [],
          'TRUNK': []
        },
        editRow: "",
        filterTypes: Utils.filterTypes,
        unwatch: null,
      }
    },
    created() {
      this.computeHeaders();
    },
    mounted() {
      this.isUnifyPhoneStandalone() === true && this.validators.TRUNK.push(this.isRequired);
      this.fetchDevices();
      this.unwatch = store.watch(state => state.currentStep, value => {
        if (value === 3) {
          this.fetchConfiguredData();
          this.fetchData(this.options, this.search, true);
          this.$emit('form-changed');
        }
      });
      window.addEventListener('resize', this.handleResize);
    },
    unmounted() {
      window.removeEventListener('resize', this.handleResize);
    },
    beforeDestroy() {
      this.unwatch();
    },
    computed: {
      ...mapState([
          'customer', 'migrationUsers', 'selectedMigrationUsers', 'selectedMigrationSites', 'selectAllUsers', 'selectAllPorting', 
          'stepperConsistencyReport', 'regionals', 'migrationUserErrors', 'deviceTypes', 'loggedUser', 'accountRoles', 'trunks'
        ]),
      ...mapGetters(['getCombinedUsers', 'getStepperConsistencyErrorText']),
      totalElements() {
        return this.migrationUsers && this.migrationUsers.paging && this.migrationUsers.paging.totalElements || 0;
      },
      cssVariables () {
        // We do not count the edit column
        return Utils.calculateHeaderWidth(this.computedHeaders.length - 1);
      },
      items() {
        return this.getCombinedUsers();
      },
      migrationItems: {
        get: function () {
          return this.selectedMigrationUsers;
        },
        set: function (value) {
          if (value.length > this.migrationItems.length && this.selectedAllPorting) {
            this.selectedAllPorting = false;
          }
          store.commit('setSelectedMigrationUsers', value);
        }
      },
      selectedAll: {
        get: function () {
          return this.selectAllUsers;
        },
        set: function (value) {
          store.commit('setSelectAllUsers', value);
        }
      },
      selectedAllPorting: {
        get: function () {
          return this.selectAllPorting;
        },
        set: function (value) {
          store.commit('setSelectAllPorting', value);
        }
      },
      firstname: {
        get: function () {
          return this.migrationUsers && this.migrationUsers.search && this.migrationUsers.search.firstname || '';
        },
        set: function (value) {
          this.search.firstname = value;
          this.pageReset();
        }
      },
      lastname: {
        get: function () {
          return this.migrationUsers && this.migrationUsers.search && this.migrationUsers.search.lastname || '';
        },
        set: function (value) {
          this.search.lastname = value;
          this.pageReset();
        }
      },
      email: {
        get: function () {
          return this.migrationUsers && this.migrationUsers.search && this.migrationUsers.search.email || '';
        },
        set: function (value) {
          this.search.email = value;
          this.pageReset();
        }
      },
      language: {
        get: function () {
          return this.migrationUsers && this.migrationUsers.search && this.migrationUsers.search.language || '';
        },
        set: function (value) {
          this.search.language = value;
          this.pageReset();
        }
      },
      number: {
        get: function () {
          return this.migrationUsers && this.migrationUsers.search && this.migrationUsers.search.number || '';
        },
        set: function (value) {
          this.search.number = value
          this.pageReset();
        }
      },
      extension: {
        get: function () {
          return this.migrationUsers && this.migrationUsers.search && this.migrationUsers.search.extension || '';
        },
        set: function (value) {
          this.search.extension = value
          this.pageReset();
        }
      },
      role: {
        get: function () {
          return this.migrationUsers && this.migrationUsers.search && this.migrationUsers.search.role || '';
        },
        set: function (value) {
          this.search.role = value
          this.pageReset();
        }
      },
      macAddress: {
        get: function () {
          return this.migrationUsers && this.migrationUsers.search && this.migrationUsers.search.macAddress || '';
        },
        set: function (value) {
          this.search.macAddress = value
          this.pageReset();
        }
      },
      ipAddress: {
        get: function () {
          return this.migrationUsers && this.migrationUsers.search && this.migrationUsers.search.ipAddress || '';
        },
        set: function (value) {
          this.search.ipAddress = value
          this.pageReset();
        }
      },
      phoneType: {
        get: function () {
          return this.migrationUsers && this.migrationUsers.search && this.migrationUsers.search.phoneType || '';
        },
        set: function (value) {
          this.search.phoneType = value
          this.pageReset();
        }
      },
      trunkName: {
        get: function () {
          return this.migrationUsers && this.migrationUsers.search && this.migrationUsers.search.trunkName || '';
        },
        set: function (value) {
          this.search.trunkName = value;
          this.pageReset();
        }
      },
      search() {
        return {
            firstname: this.firstname,
            lastname: this.lastname,
            email: this.email,
            language: this.language,
            number: this.number,
            extension: this.extension,
            role: this.role,
            macAddress: this.macAddress,
            ipAddress: this.ipAddress,
            phoneType: this.phoneType,
            trunkName: this.trunkName,
        }
      },
      options: {
        get: function () {
          return this.migrationUsers.paging;
        },
        set: function (value) {
          this.fetchData(value, this.search);
        }
      },
      languages() {
        return this.regionals && this.regionals.languages;
      },
      languagesFilter() {
        const regionalFilter = [ { name: this.filterTypes[0].filterLabel, localeCode: this.filterTypes[0].filterValue},
         { name: this.filterTypes[1].filterLabel, isoCode: this.filterTypes[1].filterValue}];
        return this.regionals && regionalFilter.concat(this.regionals.languages);
      },
      getDeviceTypes() {
        return this.devices;
      },
      getRoles() {
        return this.accountRoles;
      },
      getTrunks() {
        return this.trunks && this.trunks.records || [];
      },
    },  
    methods: {
      isUnifyVideoAccount () {
        return this.loggedUser && this.loggedUser.user && this.loggedUser.user.isUnifyVideo === true;
      },
      isUnifyPhoneStandalone () {
        return this.loggedUser && this.loggedUser.user && this.loggedUser.user.isUnifyPhoneStandalone === true;
      },
      hasEditRolesPermission () {
        return this.loggedUser && this.loggedUser.user && this.loggedUser.user.hasEditRolesPermission === true;
      },
      getColor(item) {
        let color = 'primary';
        const validationError = this.migrationUserErrors[item.uuid]
                                && Object.keys(this.migrationUserErrors[item.uuid]).find(key => !!this.migrationUserErrors[item.uuid][key]);
        if (validationError) {
          color = 'error';
        }
        return color;
      },
      toggleEdit(item) {
        if (item.isSelected && item.uuid !== this.editRow){
          this.editRow = item.uuid;
        } else {
          this.editRow = '';
        }
      },
      isInEditMode(item) {
        return item.isSelected && item.uuid === this.editRow;
      },
      isHandledDeviceType(item) {
        let retVal = true;
        if (item && item.primaryDevice) {
          retVal = item.primaryDevice.phoneType !== "SoftPhone" &&
                   item.primaryDevice.phoneType !== "Other" &&
                   item.primaryDevice.phoneType !== "Unknown" &&
                   item.primaryDevice.phoneType !== "None";
        }
        return retVal;
      },
      async validateNow(elem) {
        await this.$nextTick();
        this.$refs[elem] && this.$refs[elem].validate();
      },
      selectAll(value) {
        if (value) {
          //In 'Select All' option only edited users are added 
          store.commit('setEditedUsersForMigration');
          //Validate all the items in the current page
          this.validateRows(this.items);
        } else {
          // We deselect all users, reset error objects and stepper consistency report
          this.editRow = '';
          this.migrationItems = [];
          store.commit('resetAllMigrationUserErrors');
          this.resetStepConsistencyErrors()
        }
        this.selectedAll = value;
      },
      async editCell(item, uuid, field) {
        store.commit('setEditedUser', item);
        const uuids = [uuid];
        this.resetItemConsistencyError(uuids, field);
        this.validateRow(true, item);
      },
      async resetStepConsistencyErrors() {
        if (this.stepperConsistencyReport) {
          const somethingChanged = await store.dispatch('resetStepStepperConsistencyError', 3);
          if (somethingChanged) {
            this.$emit('form-changed');
          }
        }
      },
      async resetItemConsistencyError(uuids, field) {
        if (this.stepperConsistencyReport) {
          const somethingChanged = await store.dispatch('resetItemStepperConsistencyError', {uuids, field});
          if (somethingChanged) {
            this.validateStep();
            this.$emit('form-changed');
          }
        }
      },
      togglePorting(value, item) {
        item.numberPorting = value;
        if (this.selectedAllPorting && !value) {
          this.selectedAllPorting = false;
        }
        this.editCell(item, item.uuid, 'NUMBER_PORTING');
      },
      togglePortingAll(value) {
        store.commit('togglePortingAll', value);
      },
      pageReset(){
        //If a new filter is applied we reset page number, the select all option and the selected items
        this.options.page = 1;
        this.selectedAll = false;
        this.migrationItems = [];
        this.fetchData(this.options, this.search);
      }, 
      detectQueryChange(options, search) {
         const { page, itemsPerPage } = options
         const { firstname, lastname, email, language, number, extension, role, macAddress, ipAddress, phoneType, trunkName } = search
         const noChange = page && itemsPerPage && this.migrationUsers && this.migrationUsers.paging && this.migrationUsers.search
                          && this.migrationUsers.paging.page === page && this.migrationUsers.paging.itemsPerPage === itemsPerPage
                          && this.migrationUsers.search.firstname === firstname && this.migrationUsers.search.lastname === lastname
                          && this.migrationUsers.search.email === email && this.migrationUsers.search.language === language
                          && this.migrationUsers.search.number === number && this.migrationUsers.search.extension === extension
                          && this.migrationUsers.search.macAddress === macAddress && this.migrationUsers.search.role === role
                          && this.migrationUsers.search.ipAddress === ipAddress && this.migrationUsers.search.phoneType === phoneType
                          && this.migrationUsers.search.trunkName === trunkName;
         return !noChange
      },
      getQuery(options, search) {
         const { page, itemsPerPage } = options
         const pagingParameters = { page, itemsPerPage }
         return Utils.encodeBase64Url(JSON.stringify({ options: pagingParameters, search }));
      },
      fetchData: async function(options, search, initialize) {
        if (this.loading) { return; }
        try {
          this.loading = true;
          const migrationSite = this.selectedMigrationSites && this.selectedMigrationSites.length && this.selectedMigrationSites[0];
          if (initialize || (this.detectQueryChange(options,search) && migrationSite)) {
            this.search.site = migrationSite && migrationSite.uuid
            this.search.removal = {filterValue: 'empty'}
            this.search.filterNonMigrated = true;
            await store.dispatch('getMigrationUsers', {customerId: this.customer.uuid, query: this.getQuery(options, search)});
          }
        } finally {
          this.loading = false;
          if (this.selectedAll) {
            this.validateRows(this.items);
          } 
        }
      },
      fetchConfiguredData: async function() {
        if (this.loadingConfigured) { return; }
        try {
          this.loadingConfigured = true;
          const migrationSite = this.selectedMigrationSites && this.selectedMigrationSites.length && this.selectedMigrationSites[0];
          if (migrationSite) {
            // Reminder: "itemsPerPage: -1" means we are getting ALL the requested items in a single page, USE WITH CAUTION!
            const options = { page: 1, itemsPerPage: -1};
            // Building the correct filter
            const search = {};
            const migrationSite = this.selectedMigrationSites && this.selectedMigrationSites.length && this.selectedMigrationSites[0];
            search.site = migrationSite && migrationSite.uuid
            search.removal = { filterValue: 'empty' };
            search.alreadyConfigured = true;
            await store.dispatch('getConfiguredUsers', {customerId: this.customer.uuid, query: this.getQuery(options, search)});
          }
        } finally {
          this.loadingConfigured = false;
        }
      },
      isDigitsOnlyOrEmpty: field => (/^\d+$/.test(field) || field === "") || 'stepper.isDigitsOnly',
      isEmail: field => 
            /^[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])$/.test(field) || 
            'stepper.isEmail',
      isMACaddress: field => /^([0-9A-Fa-f]{2}[:-]){5}([0-9A-Fa-f]{2})$|^$/.test(field) || 'stepper.isMACaddress',
      isRequired: field => (!!field && field.trim() != "") || "stepper.isRequired",
      isXSSsanitized: field => /^((?!<.*?>).)*$/.test(field) || 'stepper.isXSSsanitized',
      getConsistencyErrorText(uuid, fieldName) {
        let func = this.getStepperConsistencyErrorText;
        return function() {
          const errorTxt = func(uuid, fieldName);
          if (errorTxt) {
            return errorTxt;
          } else {
            return true;
          }
        };
      },
      validateStep() {
        if (this.selectedAll) {
          this.validateRows(this.items);
        } else {
          this.validateRows(this.migrationItems);
        }
      },
      validateRow(value, item) {
        if (value) {
          const errorObj = this.createErrorObj(item)
          if(!this.migrationUserErrors[item.uuid] || !Utils.isEqualErrorObj(this.migrationUserErrors[item.uuid], errorObj)) {
            store.commit('setMigrationUserErrors', { userUuid: item.uuid, errorObj });
          }
        } else {
          this.resetItemConsistencyError(this.getObjectId(item, 'ALL'));
          store.commit('resetMigrationUserErrors', item.uuid);
        }
      },
      validateRows(items) {
        const errorObjs = {};
        items.forEach(item => {
          errorObjs[item.uuid] = this.createErrorObj(item);
        })
        let errorChanges = Object.keys(errorObjs).find(uuid => {
          return !this.migrationUserErrors[uuid] || !Utils.isEqualErrorObj(this.migrationUserErrors[uuid], errorObjs[uuid]);
        });
        if (errorChanges) {
          store.commit('setAllMigrationUserErrors', errorObjs);
        }
      },
      createErrorObj(item) {
        const errorObj = {};
        Object.keys(this.validators).forEach(field => {
            const objectId = this.getObjectId(item, field);
            const errorMsg = this.getValidatorRules(objectId, field).map(validator => validator(this.getValueToValidate(item, field)))
                            .find(errorText => errorText !== true) || '';
            errorObj[field] = this.$t(errorMsg);
        })
        return errorObj;
      },
      getObjectId(item, field) {
        let uuids = [];
        switch(field) {
          case 'FIRST_NAME':
          case 'LAST_NAME':
          case 'E-MAIL':
          case 'LANGUAGE':
          case 'NUMBER_PORTING':
          case 'ROLE':
          case 'TRUNK':
            return item.uuid;
          case 'EXTENSION':
            return item.primaryExtension && item.primaryExtension.uuid;
          case 'MAC_ADDRESS':
          case 'PHONE_TYPE':
            return item.primaryDevice && item.primaryDevice.uuid;
          case 'ALL':
            uuids = [item.uuid];
            if (item.primaryExtension) {
              uuids.push(item.primaryExtension.uuid);
            }
            if (item.primaryDevice) {
              uuids.push(item.primaryDevice.uuid);
            }
            return uuids;
          default:
            return '';
        }
      },
      getValueToValidate(item, field) {
        switch(field) {
          case 'FIRST_NAME':
            return item.firstname;
          case 'LAST_NAME':
            return item.lastname;
          case 'E-MAIL':
            return item.email;
          case 'LANGUAGE':
            return item.language;
          case 'EXTENSION':
            return item.primaryExtension && item.primaryExtension.number;
          case 'ROLE':
            return item.role;
          case 'NUMBER_PORTING':
            return item.numberPorting;
          case 'MAC_ADDRESS':
            return item.primaryDevice && item.primaryDevice.macAddress;
          case 'PHONE_TYPE':
            return item.primaryDevice && item.primaryDevice.phoneType;
          case 'TRUNK':
            return item.trunkName;
          default:
            return '';
        }
      },
      getFieldErrorText(item, uuid, field) {
        return (this.migrationUserErrors[item.uuid] && this.migrationUserErrors[item.uuid][field]) || this.getStepperConsistencyErrorText(uuid, field);
      },
      getValidatorRules(uuid, field) {
        const validators = this.validators
        return validators[field].concat([this.getConsistencyErrorText(uuid, field)]);
      },
      getLanguageNameFromCode(localeCode) {
        const languages = this.regionals && this.regionals.languages;
        for (let i = 0; i < languages.length; i++) {
          if (languages[i].localeCode === localeCode) {
            return languages[i].name;
          }
        }
        return '';
      },
      getRoleNameFromId(roleId) {
        const roles = this.accountRoles;
        for (let i = 0; i < roles.length; i++) {
          if (roles[i].id === roleId) {
            return roles[i].displayName;
          }
        }
        return '';
      },
      fetchDevices: async function() {
        await store.dispatch('getDevices');
        this.devices = [];
        this.deviceTypes.forEach(device => {
          this.devices.push({ deviceName: this.$t(device.deviceName), deviceValue: device.deviceValue });
        });
      },
      handleResize() {
        clearTimeout(this.resizeTimer);
        this.resizeTimer = setTimeout(this.computeHeaders, 100);
      },
      computeHeaders() {
        let filteredHeaders = this.commonHeaders;
        if (this.isUnifyVideoAccount() === true) {
          if (this.hasEditRolesPermission() === true) {
            filteredHeaders = filteredHeaders.concat(this.roleHeader)
          }
          filteredHeaders = filteredHeaders.concat(this.trunkHeaderOptional)
        } else if (this.isUnifyPhoneStandalone() === true) {
          filteredHeaders = filteredHeaders.concat(this.trunkHeaderRequired)
        } else {
          filteredHeaders = filteredHeaders.concat(this.extensionHeader)
          if (this.hasEditRolesPermission() === true) {
            filteredHeaders = filteredHeaders.concat(this.roleHeader)
          }
          filteredHeaders = filteredHeaders.concat(this.numberPortingHeader)
          filteredHeaders = filteredHeaders.concat(this.deviceHeaders)
        }
        const maxWidth = (this.isUnifyVideoAccount() || this.isUnifyPhoneStandalone()) ? (window.innerWidth - 200) : 1386;
        const calcWidth = Math.round(maxWidth / (filteredHeaders.length - 1));
        const flexWidth = calcWidth >= 126 ? calcWidth.toString() : '126';
        for (const header of filteredHeaders) {
          !header.width && (header.width = flexWidth);
        }
        this.computedHeaders = filteredHeaders;
        Array.from(document.getElementsByClassName('configuration-users-headers')).forEach(elem => elem.style['min-width'] = `${flexWidth}px`);
      },
      getDeviceName(name) {
        let deviceName = '';
        this.devices.forEach(device => {
          if (device.deviceValue === name) {
            deviceName = device.deviceName;
          }
        })
        return deviceName;
      }
    }
  }
</script>
<style>
  .configuration-users-headers {
      width: var(--width) !important;
  }
</style>
