<template>
  <v-container fluid class="umt-container">
    <v-row  no-gutters class="fill-height flex-nowrap">
      <v-col class="profile-col home-page">
        <app-logo/>
        <uo-account :companyName='companyName' :phoneNumber='mainNumber'/>
      </v-col>
      <v-col class="customer-col">
        <customer-view/>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import { mapState } from 'vuex';
  import AppLogo from '@/components/appLogo';
  import UoAccount from '@/components/uoAccount';
  import CustomerView from '@/components/customerView';

  export default {
    name: 'Home',
    components: {
      AppLogo,
      UoAccount,
      CustomerView
    },
    computed: {
      ...mapState(['loggedUser']),
      companyName() {
        return this.loggedUser && this.loggedUser.account && this.loggedUser.account.company;
      },
      mainNumber() {
        return this.loggedUser && this.loggedUser.user && this.loggedUser.user.customers && this.loggedUser.user.customers.length && this.loggedUser.user.customers[0].mainNumber;
      }
    }
  }
</script>

<style scoped>
.customer-col {
    min-width: 300px;
    padding: 0 3rem 3rem 3rem;
    background-color: white;
  }
</style>
