<template>
  <v-stepper v-model="currStep" alt-labels>
      <v-stepper-header class="stepper-header-container pt-4 pb-1">
        <v-stepper-step
          :complete="currStep > 1"
          step="1" :rules="[() => hasStepConsistencyError(1)]"
          error-icon="warning_amber" complete-icon="done"
        >
           <div class="text-center" >
                <div>{{ $t("stepper.selectConfigureBg") }}</div>
                <div v-bind:class="!hasStepConsistencyError(1) ? 'visible' : 'invisible'"
                  class="stepper-error-text">{{ getStepConsistencyErrors(1).length }} {{ getStepConsistencyErrors(3).length | pluralize($t("common.error"), $t("common.errors")) }}
                </div>
          </div>
        </v-stepper-step>
  
        <v-divider></v-divider>
  
        <v-stepper-step
          :complete="currStep > 2"
          step="2" :rules="[() => hasStepConsistencyError(2)]"
          error-icon="warning_amber" complete-icon="done"
        >
          <div class="text-center" >
                <div>{{ $t("stepper.selectConfigureSite") }}</div>
                <div v-bind:class="!hasStepConsistencyError(2) ? 'visible' : 'invisible'" 
                  class="stepper-error-text">{{ getStepConsistencyErrors(2).length }} {{ getStepConsistencyErrors(2).length | pluralize($t("common.error"), $t("common.errors")) }}
                </div>
          </div>
        </v-stepper-step>
  
        <v-divider></v-divider>
  
        <v-stepper-step 
          :complete="currStep > 3"
          step="3" :rules="[() => hasStepConsistencyError(3)]"
          error-icon="warning_amber" complete-icon="done"
        >
          <div class="text-center" >
              <div>{{ $t("stepper.selectConfigureUsers") }}</div>
              <div v-bind:class="!hasStepConsistencyError(3) ? 'visible' : 'invisible'"
                class="stepper-error-text">{{ getStepConsistencyErrors(3).length }} {{ getStepConsistencyErrors(3).length | pluralize($t("common.error"), $t("common.errors")) }}
              </div>
          </div>
        </v-stepper-step>

        <v-divider v-if="!isUnifyVideoAccount() && !isUnifyPhoneStandalone()"></v-divider>

        <v-stepper-step v-if="!isUnifyVideoAccount() && !isUnifyPhoneStandalone()"
          step="4" :rules="[() => hasStepConsistencyError(4)]"
          error-icon="warning_amber" complete-icon="done"
        >
          <div class="text-center" >
              <div>{{ $t("stepper.selectConfigureFeatures") }}</div>
              <div v-bind:class="!hasStepConsistencyError(4) ? 'visible' : 'invisible'"
                class="stepper-error-text">{{ getStepConsistencyErrors(4).length }} {{ getStepConsistencyErrors(4).length | pluralize($t("common.error"), $t("common.errors")) }}
              </div>
          </div>
        </v-stepper-step>
      </v-stepper-header>
  
      <v-form ref="form" v-model="valid">
        <v-stepper-items>
          
          <v-stepper-content step="1">
              <configuration-bgs/>
          </v-stepper-content>
    
          <v-stepper-content step="2">
            <configuration-sites ref="sites" @form-changed="validateForm"/>
          </v-stepper-content>
    
          <v-stepper-content step="3">
              <configuration-users ref="users" @form-changed="validateForm"/>           
          </v-stepper-content>

          <v-stepper-content step="4">
            <configuration-features ref="features" @form-changed="validateForm"/>

            <cancel-stepper-dialog class="lvl2-elevation"
            v-model="showCancelDialog"
            @close-dialog="closeCancelDialog"
            @confirm-dialog="confirmCancel"/>
          </v-stepper-content>
        </v-stepper-items>
      </v-form>
        <v-container fluid>
          <!-- Buttons -->
          <v-row justify="space-between">
            <v-col sm="auto">
              <v-btn
                  outlined text width="125"
                  class="button-text-16 no-text-transform ma-4"
                  @click="onCancel()"
                >
                  {{ $t("stepper.cancel") }}
              </v-btn>
            </v-col>
            <v-col sm="auto">
              <v-btn
                outlined color="primary" width="125"
                class="button-text-16 no-text-transform ma-4"
                @click="currStep=onPressBack(currStep)"
              >
                {{ $t("stepper.previous") }}
              </v-btn> 
              <v-btn v-if="showNextButton()"
                outlined color="primary" width="125"
                :disabled="!enableNextStep(currStep)"
                class="button-text-16 no-text-transform ma-4"
                @click="currStep = currStep + 1"
              >
                {{ $t("stepper.next") }}
              </v-btn>
              <v-btn v-if="showSaveButton()"
                color="primary" width="125"
                :dark="!!enableSave() && !loading"
                :loading='loading'
                :disabled="!enableSave() || loading"
                class="button-text-16 no-text-transform ma-4"
                @click="onSave()"
              >
                {{ $t("stepper.save") }}
                <template v-slot:loader>
                   <span>{{ $t("stepper.saving") }}</span>
                </template>
              </v-btn>
            </v-col>
          </v-row>
          <!-- Consistency errors -->
          <v-row>
            <v-col sm="6" v-if='getConsistencyReport && getConsistencyReport.length'>
              <v-card class="ma-4 report-card" elevation="0" color="light">
                <v-card-title class="text-error-heading"> 
                    <v-icon left color="error">warning</v-icon>{{ $t("common.errors") }}
                </v-card-title>
                <v-virtual-scroll
                  :items="getConsistencyReport"
                  max-height="300" item-height="20"
                  class="pa-2"
                >
                  <template v-slot:default="{ index, item }">
                    <v-list-item :key="index">
                      <v-list-item-content>
                        <v-list-item-title class="report-text-error">
                           {{ index + 1 }}. {{ item.message }}
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                </v-virtual-scroll>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </v-stepper>
    
</template>

<script>
  import { mapState, mapGetters } from 'vuex';
  import store from '../store';
  import router from '../router';
  import * as RestApiHandler from '../common/restApiHandler';
  import { pluralize }  from '../common/filters';
  import CancelStepperDialog from "../components/cancelStepperDialog";
  import ConfigurationBgs from '../components/configurationBgs.vue';
  import ConfigurationSites from '../components/configurationSites.vue';
  import ConfigurationUsers from "../components/configurationUsers";
  import ConfigurationFeatures from '../components/configurationFeatures.vue';

export default {
  name: 'Stepper',
  components: {
    CancelStepperDialog,
    ConfigurationBgs,
    ConfigurationSites,
    ConfigurationUsers,
    ConfigurationFeatures
  },
  data() {
    return {
      showCancelDialog: false,
      valid: false,
      loading: false,
    }
  },
  filters: {
      pluralize
  },
  computed: {
    ...mapState([
        'customer', 'currentStep', 'migrationUsers', 'migrationFeatures' ,'selectedMigrationBusinessGroups', 'selectedMigrationSites', 'selectedMigrationUsers',
        'selectAllUsers', 'selectAllPorting', 'selectAllFeatures', 'selectedMigrationFeatures', 'stepperConsistencyReport', 'loggedUser'
      ]),
    ...mapGetters(['getStepConsistencyErrors', 'getSiteValidationErrors', 'getUserValidationErrors', 'getFeatureValidationErrors']),
    getConsistencyReport () {
      return this.stepperConsistencyReport;
    },
    currStep: {
      get: function () {
        return this.currentStep;
      },
      set: function (value) {
        store.commit('setCurrentStep', value);
      }
    },
  },
  methods: {
      isUnifyVideoAccount () {
        return this.loggedUser && this.loggedUser.user && this.loggedUser.user.isUnifyVideo === true;
      },
      isUnifyPhoneStandalone () {
        return this.loggedUser && this.loggedUser.user && this.loggedUser.user.isUnifyPhoneStandalone === true;
      },
      showNextButton () {
        if (this.isUnifyVideoAccount() || this.isUnifyPhoneStandalone()) {
          return this.currStep != 3;
        } else {
          return this.currStep != 4;
        }
      },
      showSaveButton () {
        return !this.showNextButton();
      },
      enableNextStep(s) {
        switch(s) {
          case 1:
            return this.selectedMigrationBusinessGroups && this.selectedMigrationBusinessGroups.length;
          case 2:
            return this.selectedMigrationSites && this.selectedMigrationSites.length && 
                   this.hasSiteValidationError();
          case 3:
            return (this.selectedMigrationUsers && this.selectedMigrationUsers.length || this.selectAllUsers) &&
                   this.hasUserValidationError();
          default:
            return false;
        }
      },
      enableSave() {
        if (this.isUnifyVideoAccount() || this.isUnifyPhoneStandalone()) {
          return (this.selectedMigrationUsers && this.selectedMigrationUsers.length || this.selectAllUsers) && this.hasUserValidationError();
        } else {
          return this.hasSiteValidationError() && this.hasUserValidationError() && this.hasFeatureValidationError();
        }
      },
      onCancel() {
          this.showCancelDialog = true;
      },
      onPressBack(s){
          if ( s > 1) {
            s = s - 1;
            return s;
          } else {
            this.s = 1;
            this.showCancelDialog = true;
            return this.s
          }
      },
      confirmCancel() {
        store.commit("resetMigrationItems");
        router.push('/');
      },
      closeCancelDialog() {
          this.showCancelDialog = false;
          return this.currStep ? this.currStep : this.currStep = 1;
      },
      async onSave() {
        this.loading = true;
        let response;
        var customerId = this.selectedMigrationSites[0].customerId;
        var selectedChanges = {};
        selectedChanges.selectedMigrationBusinessGroups = this.selectedMigrationBusinessGroups;
        selectedChanges.selectedMigrationSites = this.selectedMigrationSites;
        selectedChanges.selectedMigrationUsers = this.selectedMigrationUsers;
        selectedChanges.selectedMigrationFeatures = this.selectedMigrationFeatures;
        selectedChanges.selectAllUsers = this.selectAllUsers;
        selectedChanges.selectAllPorting = this.selectAllPorting;
        selectedChanges.selectAllFeatures = this.selectAllFeatures;
        selectedChanges.userSearch = this.migrationUsers.search;
        selectedChanges.featureSearch = this.migrationFeatures.search;
        selectedChanges.uoAccountId = this.customer && this.customer.uoAccountId;
        selectedChanges.isUnifyPhoneStandalone = this.isUnifyPhoneStandalone();
        
        try {
          const result = await RestApiHandler.save(JSON.stringify(selectedChanges), customerId);
          if (result.status !== 200) {
            this.$log.error('[Stepper] save error: ', result);
            store.commit("resetMigrationItems");
            router.push('/');
          } else {
            response = result.json ? await result.json() : result;
            this.$log.debug('[Stepper] response: ', response);
            if (response && response.consistencyReport) {
              store.commit('setStepperConsistencyReport', response.consistencyReport);
              this.validateSteps();
              this.validateForm();
            } else {
              store.commit('setSaveConfigurationStatus', this.$t("configureMigrate.completed"));
              if (response && response.customers) {
                store.commit('setCustomers', response.customers);
                store.commit("resetMigrationItems");
              }
              router.push('/');
            }
          }
        } catch (err) {
          let saveError = err.message || err.statusText || err;
          this.$log.error('[Stepper] save error: ', saveError);
        } finally {
          this.$emit('save-finished', this.pbxType, {
           }, response);
          this.$log.debug(`[Stepper] Save finished.`);
          this.loading = false;
        }
      },
      hasStepConsistencyError(step) {
        return !this.getStepConsistencyErrors(step).length;
      },
      hasUserValidationError() {
        return !this.getUserValidationErrors().length;
      },
      hasSiteValidationError() {
        return !this.getSiteValidationErrors().length;
      },
      hasFeatureValidationError() {
        return !this.getFeatureValidationErrors().length;
      },
      validateForm() {
        this.$refs.form.validate();
      },
      validateSteps() {
        this.$refs.sites.validateStep();
        this.$refs.users.validateStep();
        this.$refs.features.validateStep();
      }
  }
}
</script>

<style scoped>
  .stepper-header-container {
      width: 70%;
      justify-content: center;
      box-shadow: 0 0;
  }
  .v-stepper {
      text-align: -webkit-center;
      box-shadow: 0 0; 
  }
</style>
<style>
  .v-stepper__label {
      line-height: 20px;
      font-size: 13px;
      margin-top: 0.5rem;
  }
</style>
