var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-col',{style:(_vm.cssVariables)},[_c('v-row',[_c('v-data-table',{staticClass:"full-width data-table",attrs:{"item-key":"uuid","show-select":"","single-select":"","mobile-breakpoint":"0","headers":_vm.computedHeaders,"items":_vm.getCombinedSites,"options":_vm.options,"server-items-length":_vm.totalElements,"loading":_vm.loading,"no-data-text":_vm.$t('common.noDataAvailable'),"footer-props":{
          disablePagination: _vm.loading,
          itemsPerPageOptions: [5, 10, 20],
          showFirstLastPage: true,
          firstIcon: 'first_page',
          prevIcon: 'chevron_left',
          nextIcon: 'chevron_right',
          lastIcon: 'last_page',
          itemsPerPageText: _vm.$t('common.rowsPerPage')
      }},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"header.name",fn:function(ref){
      var header = ref.header;
return [_c('v-text-field',{attrs:{"value":_vm.name,"label":_vm.$t(header.text),"append-icon":"search","clear-icon":"close","single-line":"","hide-details":"","flat":"","solo":"","clearable":"","placeholder":_vm.$t(header.text)},on:{"change":function ($event) { return _vm.name = $event; },"click:clear":function ($event) { return _vm.name = ''; }}})]}},{key:"header.code",fn:function(ref){
      var header = ref.header;
return [_c('v-combobox',{attrs:{"items":!_vm.code ? _vm.filterTypes : undefined,"item-text":"filterLabel","item-value":"filterValue","label":_vm.$t(header.text),"hide-no-data":"","hide-details":"","hide-selected":"","append-icon":"search","clear-icon":"close","solo":"","single-line":"","flat":"","clearable":"","placeholder":_vm.$t(header.text)},on:{"change":function ($event) { return _vm.code = $event; },"click:clear":function ($event) { return _vm.code = ''; }},model:{value:(_vm.code),callback:function ($$v) {_vm.code=$$v},expression:"code"}})]}},{key:"header.locationName",fn:function(ref){
      var header = ref.header;
return [_c('v-combobox',{attrs:{"items":!_vm.locationName ? _vm.filterTypes : undefined,"item-text":"filterLabel","item-value":"filterValue","label":_vm.$t(header.text),"hide-no-data":"","hide-details":"","hide-selected":"","append-icon":"search","clear-icon":"close","solo":"","single-line":"","flat":"","clearable":"","placeholder":_vm.$t(header.text)},on:{"change":function ($event) { return _vm.locationName = $event; },"click:clear":function ($event) { return _vm.locationName = ''; }},model:{value:(_vm.locationName),callback:function ($$v) {_vm.locationName=$$v},expression:"locationName"}})]}},{key:"header.country",fn:function(ref){
      var header = ref.header;
return [_c('v-select',{attrs:{"items":_vm.countryNamesFilter,"item-text":"name","item-value":"isoCode","value":_vm.country,"label":_vm.$t(header.text),"append-icon":"search","clear-icon":"close","single-line":"","hide-details":"","flat":"","solo":"","clearable":"","placeholder":_vm.$t(header.text),"menuProps":{ auto: true, bottom: true, offsetY: true }},on:{"change":function ($event) { return _vm.country = $event; },"click:clear":function ($event) { return _vm.country = null; }}})]}},{key:"header.state",fn:function(ref){
      var header = ref.header;
return [_c('v-select',{attrs:{"items":_vm.supportedStates,"item-text":"name","item-value":"isoCode","value":_vm.state,"label":_vm.$t(header.text),"append-icon":"search","clear-icon":"close","single-line":"","hide-details":"","flat":"","solo":"","clearable":"","placeholder":_vm.$t(header.text),"menuProps":{ auto: true, bottom: true, offsetY: true }},on:{"change":function ($event) { return _vm.state = $event; },"click:clear":function ($event) { return _vm.state = null; }}})]}},{key:"header.city",fn:function(ref){
      var header = ref.header;
return [_c('v-combobox',{attrs:{"items":!_vm.city ? _vm.filterTypes : undefined,"item-text":"filterLabel","item-value":"filterValue","label":_vm.$t(header.text),"hide-no-data":"","hide-details":"","hide-selected":"","append-icon":"search","clear-icon":"close","solo":"","single-line":"","flat":"","clearable":"","placeholder":_vm.$t(header.text)},on:{"change":function ($event) { return _vm.city = $event; },"click:clear":function ($event) { return _vm.city = ''; }},model:{value:(_vm.city),callback:function ($$v) {_vm.city=$$v},expression:"city"}})]}},{key:"header.streetName",fn:function(ref){
      var header = ref.header;
return [_c('v-combobox',{attrs:{"items":!_vm.streetName ? _vm.filterTypes : undefined,"item-text":"filterLabel","item-value":"filterValue","label":_vm.$t(header.text),"hide-no-data":"","hide-details":"","hide-selected":"","append-icon":"search","clear-icon":"close","solo":"","single-line":"","flat":"","clearable":"","placeholder":_vm.$t(header.text)},on:{"change":function ($event) { return _vm.streetName = $event; },"click:clear":function ($event) { return _vm.streetName = ''; }},model:{value:(_vm.streetName),callback:function ($$v) {_vm.streetName=$$v},expression:"streetName"}})]}},{key:"header.postalCode",fn:function(ref){
      var header = ref.header;
return [_c('v-combobox',{attrs:{"items":!_vm.postalCode ? _vm.filterTypes : undefined,"item-text":"filterLabel","item-value":"filterValue","label":_vm.$t(header.text),"hide-no-data":"","hide-details":"","hide-selected":"","append-icon":"search","clear-icon":"close","solo":"","single-line":"","flat":"","clearable":"","placeholder":_vm.$t(header.text)},on:{"change":function ($event) { return _vm.postalCode = $event; },"click:clear":function ($event) { return _vm.postalCode = ''; }},model:{value:(_vm.postalCode),callback:function ($$v) {_vm.postalCode=$$v},expression:"postalCode"}})]}},{key:"header.timeZoneName",fn:function(ref){
      var header = ref.header;
return [_c('v-select',{attrs:{"items":_vm.timeZones,"item-text":"name","item-value":"name","label":_vm.$t(header.text),"append-icon":"search","clear-icon":"close","single-line":"","hide-details":"","flat":"","solo":"","clearable":"","placeholder":_vm.$t(header.text),"menuProps":{ auto: true, bottom: true, offsetY: true }},on:{"change":function ($event) { return _vm.timeZoneName = $event; },"click:clear":function ($event) { return _vm.timeZoneName = null; }}})]}},{key:"item.data-table-select",fn:function(ref){
      var item = ref.item;
      var select = ref.select;
return [_c('v-simple-checkbox',{attrs:{"color":"primary","value":item.isSelected,"ripple":false,"dense":""},on:{"input":function ($event) {select($event); _vm.editRow = '';}}})]}},{key:"item.edit",fn:function(ref){
      var item = ref.item;
return [_c('v-btn',{class:item.isSelected ? 'visible' : 'invisible',attrs:{"icon":"","small":"","color":_vm.getColor(item)},on:{"click":function($event){return _vm.toggleEdit(item)}}},[_c('v-icon',[_vm._v("edit")])],1)]}},{key:"item.name",fn:function(ref){
      var item = ref.item;
return [(_vm.isSiteEditable(item) && _vm.isInEditMode(item) && _vm.validateNow('name'))?_c('span',[_c('v-tooltip',{attrs:{"disabled":!_vm.getFieldErrorText(item, item.uuid, 'SITE_NAME'),"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
return [_c('v-form',{ref:"name"},[_c('v-combobox',_vm._g({attrs:{"items":_vm.accountSites(item.name),"item-text":"name","item-value":"value","value":_vm.getValueForSiteName(item),"rules":_vm.getValidatorRules(item, item.uuid, 'SITE_NAME'),"hide-details":"","outlined":"","solo":"","flat":"","dense":""},on:{"change":function ($event) {item.name = $event.value || $event; item.translatableName = $event.translatableName; _vm.editCell(item, item.uuid, 'SITE_NAME');}}},on))],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.getFieldErrorText(item, item.uuid, 'SITE_NAME')))])])],1):_c('span',[_vm._v(_vm._s(_vm.getValueForSiteName(item)))])]}},{key:"item.code",fn:function(ref){
      var item = ref.item;
return [(_vm.isSiteEditable(item) && _vm.isInEditMode(item) && _vm.validateNow('code'))?_c('span',[_c('v-tooltip',{attrs:{"disabled":!_vm.getFieldErrorText(item, item.uuid, 'SITE_CODE'),"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
return [_c('v-form',{ref:"code"},[_c('v-text-field',_vm._g({attrs:{"value":item.code,"label":item.code,"rules":_vm.getValidatorRules(item, item.uuid, 'SITE_CODE'),"hide-details":"","outlined":"","solo":"","flat":"","dense":""},on:{"change":function ($event) {item.code = $event; _vm.editCell(item, item.uuid, 'SITE_CODE');}}},on))],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.getFieldErrorText(item, item.uuid, 'SITE_CODE')))])])],1):_c('span',[_vm._v(_vm._s(item.resolvedCode || item.code))])]}},{key:"item.locationName",fn:function(ref){
      var item = ref.item;
return [(item.location && _vm.isInEditMode(item) && _vm.validateNow('location'))?_c('span',[_c('v-tooltip',{attrs:{"disabled":!_vm.getFieldErrorText(item, item.location.uuid, 'LOCATION_NAME'),"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
return [_c('v-form',{ref:"location"},[_c('v-text-field',_vm._g({attrs:{"value":item.location.name,"label":item.location.name,"rules":_vm.getValidatorRules(item, item.location.uuid, 'LOCATION_NAME'),"hide-details":"","outlined":"","solo":"","flat":"","dense":""},on:{"change":function ($event) {item.location.name = $event; _vm.editCell(item, item.location.uuid, 'LOCATION_NAME');}}},on))],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.getFieldErrorText(item, item.location.uuid, 'LOCATION_NAME')))])])],1):_c('span',[_vm._v(_vm._s(item.location && item.location.name))])]}},{key:"item.country",fn:function(ref){
      var item = ref.item;
return [(item.location && _vm.isInEditMode(item) && _vm.validateNow('country'))?_c('span',[_c('v-tooltip',{attrs:{"disabled":!_vm.getFieldErrorText(item, item.location.uuid, 'COUNTRY'),"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
return [_c('v-form',{ref:"country"},[_c('v-select',_vm._g({attrs:{"items":_vm.countryNames,"item-text":"name","item-value":"isoCode","value":item.location.countryIso2Code,"rules":_vm.getValidatorRules(item, item.location.uuid, 'COUNTRY'),"outlined":"","solo":"","flat":"","dense":"","hide-details":"","menuProps":{ auto: true, bottom: true, offsetY: true }},on:{"input":function ($event) {item.location.countryIso2Code = $event; item.location.state = ''; _vm.editCell(item, item.location.uuid, 'COUNTRY');}}},on))],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.getFieldErrorText(item, item.location.uuid, 'COUNTRY')))])])],1):_c('span',[_vm._v(_vm._s(item.location && item.location.countryIso2Code && _vm.getNameByCountryIsoCode(item.location.countryIso2Code)))])]}},{key:"item.state",fn:function(ref){
      var item = ref.item;
return [(_vm.needToShowStateSelection(item.location))?_c('span',[(_vm.isInEditMode(item) && _vm.validateNow('state'))?_c('span',[_c('v-tooltip',{attrs:{"disabled":!_vm.getFieldErrorText(item, item.location.uuid, 'STATE'),"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
return [_c('v-form',{ref:"state"},[_c('v-select',_vm._g({attrs:{"items":_vm.statesByCountry(item.location),"item-text":"name","item-value":"isoCode","value":item.location.state,"rules":_vm.getValidatorRules(item, item.location.uuid, 'STATE'),"outlined":"","solo":"","flat":"","dense":"","hide-details":"","menuProps":{ auto: true, bottom: true, offsetY: true }},on:{"change":function ($event) {item.location.state = $event; _vm.editCell(item, item.location.uuid, 'STATE');}}},on))],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.getFieldErrorText(item, item.location.uuid, 'STATE')))])])],1):_c('span',[_vm._v(_vm._s(item.location && item.location.state && _vm.getStateByIsoCode(item.location.state)))])]):_vm._e()]}},{key:"item.city",fn:function(ref){
      var item = ref.item;
return [(item.location && _vm.isInEditMode(item) && _vm.validateNow('city'))?_c('span',[_c('v-tooltip',{attrs:{"disabled":!_vm.getFieldErrorText(item, item.location.uuid, 'CITY'),"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
return [_c('v-form',{ref:"city"},[_c('v-text-field',_vm._g({attrs:{"value":item.location.city,"label":item.location.city,"rules":_vm.getValidatorRules(item, item.location.uuid, 'CITY'),"hide-details":"","outlined":"","solo":"","flat":"","dense":""},on:{"change":function ($event) {item.location.city = $event; _vm.editCell(item, item.location.uuid, 'CITY');}}},on))],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.getFieldErrorText(item, item.location.uuid, 'CITY')))])])],1):_c('span',[_vm._v(_vm._s(item.location && item.location.city))])]}},{key:"item.streetName",fn:function(ref){
      var item = ref.item;
return [(item.location && _vm.isInEditMode(item) && _vm.validateNow('street'))?_c('span',[_c('v-tooltip',{attrs:{"disabled":!_vm.getFieldErrorText(item, item.location.uuid, 'STREET_NAME'),"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
return [_c('v-form',{ref:"street"},[_c('v-text-field',_vm._g({attrs:{"value":item.location.streetName,"label":item.location.streetName,"rules":_vm.getValidatorRules(item, item.location.uuid, 'STREET_NAME'),"hide-details":"","outlined":"","solo":"","flat":"","dense":""},on:{"change":function ($event) {item.location.streetName = $event; _vm.editCell(item, item.location.uuid, 'STREET_NAME');}}},on))],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.getFieldErrorText(item, item.location.uuid, 'STREET_NAME')))])])],1):_c('span',[_vm._v(_vm._s(item.location && item.location.streetName))])]}},{key:"item.postalCode",fn:function(ref){
      var item = ref.item;
return [(item.location && _vm.isInEditMode(item) && _vm.validateNow('postal'))?_c('span',[_c('v-tooltip',{attrs:{"disabled":!_vm.getFieldErrorText(item, item.location.uuid, 'POSTAL_CODE'),"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
return [_c('v-form',{ref:"postal"},[_c('v-text-field',_vm._g({attrs:{"value":item.location.postalCode,"label":item.location.postalCode,"rules":_vm.getValidatorRules(item, item.location.uuid, 'POSTAL_CODE'),"hide-details":"","outlined":"","solo":"","flat":"","dense":""},on:{"change":function ($event) {item.location.postalCode = $event; _vm.editCell(item, item.location.uuid, 'POSTAL_CODE');}}},on))],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.getFieldErrorText(item, item.location.uuid, 'POSTAL_CODE')))])])],1):_c('span',[_vm._v(_vm._s(item.location && item.location.postalCode))])]}},{key:"item.timeZoneName",fn:function(ref){
      var item = ref.item;
return [(item.location && _vm.isInEditMode(item))?_c('span',[_c('v-tooltip',{attrs:{"disabled":_vm.getFieldErrorText(item, item.location.uuid, 'TIMEZONE'),"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
return [_c('v-select',_vm._g({attrs:{"items":_vm.timeZones,"item-text":"name","item-value":"name","value":item.location.timeZoneName || 'GMT',"rules":_vm.getValidatorRules(item, item.location.uuid, 'TIMEZONE'),"outlined":"","solo":"","flat":"","dense":"","hide-details":"","menuProps":{ auto: true, bottom: true, offsetY: true }},on:{"input":function ($event) {item.location.timeZoneName = $event; _vm.editCell(item, item.location.uuid, 'TIMEZONE');}}},on))]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.getFieldErrorText(item, item.location.uuid, 'TIMEZONE')))])])],1):_c('span',[_vm._v(_vm._s((item.location && item.location.timeZoneName) || 'GMT'))])]}}],null,true),model:{value:(_vm.migrationItems),callback:function ($$v) {_vm.migrationItems=$$v},expression:"migrationItems"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }