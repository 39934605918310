var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{style:(_vm.cssVariables)},[_c('v-col',[_c('v-data-table',{staticClass:"data-table full-width",attrs:{"headers":_vm.computedHeaders,"items":_vm.items,"options":_vm.options,"server-items-length":_vm.totalElements,"loading":_vm.loading,"no-data-text":_vm.$t('common.noDataAvailable'),"footer-props":{
          disablePagination: _vm.loading,
          itemsPerPageOptions: [10, 20, 50, 100],
          showFirstLastPage: true,
          firstIcon: 'first_page',
          prevIcon: 'chevron_left',
          nextIcon: 'chevron_right',
          lastIcon: 'last_page',
          itemsPerPageText: _vm.$t('common.rowsPerPage')
      }},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"header.name",fn:function(ref){
      var header = ref.header;
return [_c('v-text-field',{attrs:{"value":_vm.name,"label":_vm.$t(header.text),"append-icon":"search","clear-icon":"close","single-line":"","hide-details":"","flat":"","solo":"","clearable":"","placeholder":_vm.$t(header.text)},on:{"change":function ($event) { return _vm.name = $event; },"click:clear":function ($event) { return _vm.name = ''; }}})]}},{key:"header.source",fn:function(ref){
      var header = ref.header;
return [_c('v-text-field',{attrs:{"value":_vm.source,"label":_vm.$t(header.text),"append-icon":"search","clear-icon":"close","single-line":"","hide-details":"","flat":"","solo":"","clearable":"","placeholder":_vm.$t(header.text)},on:{"change":function ($event) { return _vm.source = $event; },"click:clear":function ($event) { return _vm.source = ''; }}})]}},{key:"header.target",fn:function(ref){
      var header = ref.header;
return [_c('v-combobox',{attrs:{"items":!_vm.target ? _vm.filterTypes : undefined,"item-text":"filterLabel","item-value":"filterValue","label":_vm.$t(header.text),"hide-no-data":"","hide-details":"","hide-selected":"","append-icon":"search","clear-icon":"close","solo":"","single-line":"","flat":"","clearable":"","placeholder":_vm.$t(header.text)},on:{"change":function ($event) { return _vm.target = $event; },"click:clear":function ($event) { return _vm.target = ''; }},model:{value:(_vm.target),callback:function ($$v) {_vm.target=$$v},expression:"target"}})]}},{key:"header.removal",fn:function(ref){
      var header = ref.header;
return [_c('v-combobox',{attrs:{"items":!_vm.removal ? _vm.filterTypes : undefined,"item-text":"filterLabel","item-value":"filterValue","label":_vm.$t(header.text),"hide-no-data":"","hide-details":"","hide-selected":"","append-icon":"search","clear-icon":"close","solo":"","single-line":"","flat":"","clearable":"","placeholder":_vm.$t(header.text)},on:{"change":function ($event) { return _vm.removal = $event; },"click:clear":function ($event) { return _vm.removal = ''; }},model:{value:(_vm.removal),callback:function ($$v) {_vm.removal=$$v},expression:"removal"}})]}},{key:"header.siteName",fn:function(ref){
      var header = ref.header;
return [_c('v-text-field',{attrs:{"value":_vm.siteName,"label":_vm.$t(header.text),"append-icon":"search","clear-icon":"close","single-line":"","hide-details":"","flat":"","solo":"","clearable":"","placeholder":_vm.$t(header.text)},on:{"change":function ($event) { return _vm.siteName = $event; },"click:clear":function ($event) { return _vm.siteName = ''; }}})]}},{key:"header.status",fn:function(ref){
      var header = ref.header;
return [_c('v-select',{attrs:{"items":_vm.statusTypes,"item-text":"statusType","item-value":"statusValue","label":_vm.$t(header.text),"append-icon":"search","clear-icon":"close","single-line":"","hide-details":"","flat":"","solo":"","clearable":"","placeholder":_vm.$t(header.text),"menuProps":{ bottom: true, offsetY: true, maxHeight: 300 }},on:{"change":function ($event) { return _vm.status = $event; },"click:clear":function ($event) { return _vm.status = ''; }}})]}},{key:"item.status",fn:function(ref){
      var item = ref.item;
return [_c('v-chip',{directives:[{name:"show",rawName:"v-show",value:(item.status),expression:"item.status"}],class:_vm.getClass(item.status),attrs:{"label":""}},[_vm._v(" "+_vm._s(_vm.$t(item.status && "status." + item.status.toLowerCase() || "status.none"))+" ")])]}},{key:"header.primaryNumber",fn:function(ref){
      var header = ref.header;
return [_c('v-combobox',{attrs:{"items":!_vm.number ? _vm.filterTypes : undefined,"item-text":"filterLabel","item-value":"filterValue","label":_vm.$t(header.text),"hide-no-data":"","hide-details":"","hide-selected":"","append-icon":"search","clear-icon":"close","solo":"","single-line":"","flat":"","clearable":"","placeholder":_vm.$t(header.text)},on:{"change":function ($event) { return _vm.number = $event; },"click:clear":function ($event) { return _vm.number = ''; }},model:{value:(_vm.number),callback:function ($$v) {_vm.number=$$v},expression:"number"}})]}},{key:"header.digestInformation",fn:function(ref){
      var header = ref.header;
return [_vm._v(" "+_vm._s(_vm.$t(header.text))+" ")]}},{key:"header.email",fn:function(ref){
      var header = ref.header;
return [_c('v-combobox',{attrs:{"items":!_vm.email ? _vm.filterTypes : undefined,"item-text":"filterLabel","item-value":"filterValue","label":_vm.$t(header.text),"hide-no-data":"","hide-details":"","hide-selected":"","append-icon":"search","clear-icon":"close","solo":"","single-line":"","flat":"","clearable":"","placeholder":_vm.$t(header.text)},on:{"change":function ($event) { return _vm.email = $event; },"click:clear":function ($event) { return _vm.email = ''; }},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }