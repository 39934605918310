 <template>
  <v-dialog persistent no-click-animation v-model="show" max-width="500px">
    <v-card>
      <v-card-title class="text-bold-24">{{ $t('sources.importFrom') }}</v-card-title>
      <v-card-text>
        <v-container>
          <v-row no-gutters>
            <v-col>
              <v-select
                v-model="item"
                :items="getImportItems"
                @change="onSelectedPbxChanged(item)"
                outlined
                dense
                :menu-props="{ maxHeight: 400 }"
              ></v-select>
            </v-col>
            <v-col/>
          </v-row>
          <v-row  v-if="item !== 'UO'">
            <v-col cols="4">
              <v-btn color="primary" dark @click='pbxInstructions = item'>{{ $t('sources.instructions') }} </v-btn>
              <osv-instructions-dialog class="lvl3-elevation" v-if="pbxInstructions == 'OSV'" :value='true'
               @close-dialog="pbxInstructions = ''">
               </osv-instructions-dialog>
               <dls-instructions-dialog class="lvl3-elevation" v-if="pbxInstructions == 'DLS'" :value='true'
               @close-dialog="pbxInstructions = ''">
               </dls-instructions-dialog> 
               <osbiz-instructions-dialog class="lvl3-elevation" v-if="pbxInstructions == 'OSBiz'" :value='true'
               @close-dialog="pbxInstructions = ''">
               </osbiz-instructions-dialog>
               <os4k-instructions-dialog class="lvl3-elevation" v-if="pbxInstructions == 'OS4K'" :value='true'
               @close-dialog="pbxInstructions = ''">
               </os4k-instructions-dialog>
               <circuit-instructions-dialog class="lvl3-elevation" v-if="pbxInstructions == 'Circuit'" :value='true'
               @close-dialog="pbxInstructions = ''">
               </circuit-instructions-dialog>
               <osuc-instructions-dialog class="lvl3-elevation" v-if="pbxInstructions == 'OSUC'" :value='true'
               @close-dialog="pbxInstructions = ''">
               </osuc-instructions-dialog>
               <csv-instructions-dialog class="lvl3-elevation" v-if="pbxInstructions == 'CSV'" :value='true'
               @close-dialog="pbxInstructions = ''">
               </csv-instructions-dialog>
               <ngtc-instructions-dialog class="lvl3-elevation" v-if="pbxInstructions == 'NGTC'" :value='true'
               @close-dialog="pbxInstructions = ''">
               </ngtc-instructions-dialog>
            </v-col>
            <v-col cols="8" align="right">
              <v-btn v-if="item === 'CSV'" color="primary" dark @click="download('CSV', 'Users')">{{ $t('sources.users') }}</v-btn>
              <v-btn v-if="item === 'CSV'" color="primary" class="margin-left" dark @click="download('CSV', 'Template')">{{ $t('sources.template') }}</v-btn>
              <v-btn v-if="item === 'NGTC'" color="primary" class="margin-left" dark @click="download('NGTC', 'Template')">{{ $t('sources.template') }}</v-btn>
            </v-col>
          </v-row>
          <v-row v-if="item !== 'UO'">
            <v-col>
            <v-row>
              <fileUpload v-if="item !== 'UO'"
                :pbxType='item'
                :customerId='customerId'
                :hideProgress=true
                @upload-finished="uploadFinished"
                @upload-started="uploadStarted"
              ></fileUpload>
            </v-row>
            </v-col>
          </v-row>
          <v-row class="status-info" v-if="!progressIndicator">
            <v-col align="center">
              <p class="text-gray-18" v-html="getDialogStatusText">{{ getDialogStatusText }}</p>
            </v-col>
          </v-row>
          <v-row class="status-info" v-if="progressIndicator">
            <v-col align="center">
              <v-progress-circular :size="36" indeterminate color="primary"></v-progress-circular>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer v-if="item === 'UO'"></v-spacer>
        <v-btn v-if="item === 'UO'"
          color="primary"
          dark
          @click="importPbx(item)">{{ $t('sources.import') }}</v-btn>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          dark
          @click="closeDialog(item)">{{ $t('common.close') }}</v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
  import FileDownloader from '../common/fileDownloader';
  import FileUpload from "../components/fileUpload";
  import DlsInstructionsDialog from "../components/DlsInstructionsDialog";
  import OsvInstructionsDialog from "../components/OsvInstructionsDialog";
  import OsbizInstructionsDialog from "../components/OsbizInstructionsDialog";
  import Os4kInstructionsDialog from "../components/Os4kInstructionsDialog";
  import CircuitInstructionsDialog from "../components/CircuitInstructionsDialog";
  import OsucInstructionsDialog from "../components/OsucInstructionsDialog";
  import CsvInstructionsDialog from "../components/CsvInstructionsDialog";
  import NgtcInstructionsDialog from "../components/NgtcInstructionsDialog";
  import store from '../store';
  import { mapState } from 'vuex';

  export default {
    name: 'ImportPbxDialog',
    props: {
      value: Boolean,
      customerId: String
    },
    components: {
      FileUpload,
      OsvInstructionsDialog,
      DlsInstructionsDialog,
      OsbizInstructionsDialog,
      Os4kInstructionsDialog,
      CircuitInstructionsDialog,
      OsucInstructionsDialog,
      CsvInstructionsDialog,
      NgtcInstructionsDialog
    },
    data() {
      return {
        item: 'OSV',
        pbxInstructions: '',
        progressText: '',
        statusMessage: {}
      }
    },
    computed: {
      ...mapState(['importingPbx', 'dialogStatusText', 'unauthorized', 'loggedUser']),
      progressIndicator() {
        return this.importingPbx;
      },
      getDialogStatusText() {
        return this.dialogStatusText;
      },
      showConfirmDialog: {
        get () {
          return this.unauthorized;
        },
        set (value) {
          this.$emit('input', value)
        }
      },
      show: {
        get () {
          return this.value
        },
        set (value) {
          this.$emit('input', value)
        }
      },
      getImportItems() {
        const data = [
          {text: this.$t('sources.unifyOffice'), value: 'UO'},
          {text: this.$t('sources.osvExportFile'), value: 'OSV'},
          {text: this.$t('sources.os4kExportFile'), value: 'OS4K'},
          {text: this.$t('sources.osbizExportFile'), value: 'OSBiz'},
          {text: this.$t('sources.dlsExportFile'), value: 'DLS'},
          {text: this.$t('sources.circuitExportFile'), value: 'Circuit'},
          {text: this.$t('sources.ucExportFile'), value: 'OSUC'},
          {text: this.$t('sources.csvFile'), value: 'CSV'}
        ];
        if (this.loggedUser && this.loggedUser.user && this.loggedUser.user.isUnifyVideo === true) {
          data[0].text = this.$t('sources.unifyVideo');
          data.push({text: this.$t('sources.ngtcFile'), value: 'NGTC'});
        } else if (this.loggedUser && this.loggedUser.user && this.loggedUser.user.isUnifyPhoneStandalone === true) {
          data.splice(0, 1);
          data.push({text: this.$t('sources.ngtcFile'), value: 'NGTC'});
        }
        return data;
      },
    },
    methods: {
      closeDialog(pbxType) {
        store.commit('setFileUploadFormStatus', 'INITIAL');
        store.commit('setRequestStatus', {request: 'importPbx'});
        this.setDialogStatusTextForPbx(pbxType);
        this.$emit('close-dialog');
      },
      async importPbx(pbxType) {
        store.commit('setDialogStatusText', this.$t('fileUpload.importPbx', { pbxType:  pbxType } ));
        store.dispatch('importPbx', {
          pbxType,
          customerId: this.customerId
        });
      },
      onSelectedPbxChanged(pbxType) {
        store.commit('setFileUploadFormStatus', 'INITIAL');
        store.commit('setRequestStatus', {request: 'importPbx'});
        this.setDialogStatusTextForPbx(pbxType);
      },
      setDialogStatusTextForPbx(pbxType) {
        if (pbxType === 'UO') {
          let uoAccountType = this.$t('sources.unifyOffice');
          if (this.loggedUser && this.loggedUser.user && this.loggedUser.user.isUnifyVideo === true) {
            uoAccountType = this.$t('sources.unifyVideo');
          }
          store.commit('setDialogStatusText', this.$t('sources.uoImportInstructions', { uoAccountType:  uoAccountType } ));
        } else {
          store.commit('setDialogStatusText', '');
        }
      },
      uploadStarted() {
        store.commit('setImportingPbx', true);
      },
      uploadFinished(pbxType, result, response) {
        store.commit('setImportingPbx', false);
        if (result.status === 'SUCCESS') {
          store.commit('setCustomers', response && response.customers);
        }
        store.commit('setDialogStatusText', result.error || this.$t('fileUpload.uploadFinished', { pbxType:  pbxType} ));
      },
      async download(pbxType, contentType) {
        try {
          this.$log.debug(`[ImportPBXDialog] download for ${pbxType}, customer ${this.customerId}`)
          this.statusMessage = {text: `Downloading ${pbxType} ${contentType ? contentType : 'spreadsheet'}`, showProgress: true};
          const fileDownloader = new FileDownloader();
          const result = await fileDownloader.download(pbxType, this.customerId, contentType);
          if (result.status === 200) {
            this.statusMessage = {text: this.$t('common.downloadFinished')}
          } else {
            this.statusMessage = {text: result.statusText, error: true};
          }
        } catch(err) {
          this.$log.error(`[ImportPBXDialog] download for ${pbxType}, customer ${this.customerId} error:`, err);
        }
      }
    }
  }
</script>

<style scoped>
.status-info {
  min-height: 50px;
}
.template {
  min-height: 5px;
}
.margin-left {
  margin-left: 5px !important;
}
</style>
